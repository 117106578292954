import {OverlayStrategy} from '../strategy_based_active_overlays_provider';
import {merge, Observable, of} from 'rxjs';
import {Overlay} from '../../overlays_presenter';
import {lazy} from '../../../../../support/lazy';
import {delay, distinctUntilChanged, filter, map, shareReplay, switchMap} from 'rxjs/operators';
import {StartSignalProvider} from './internal/start_signal_provider';

export class StrokeRatesOverlayStrategy implements OverlayStrategy {
    @lazy()
    public get updates(): Observable<Overlay[]> {
        /**
         * Show 8 seconds after starting
         * 8 seconds after the split times overlay was shown, show the stroke rates overlay for 15 seconds
         */
        return merge(
            //8 seconds after the start signal
            this.startSignalProvider.signal.pipe(
                distinctUntilChanged(),
                switchMap(isStarting => {
                    if (isStarting) {
                        return merge(of(true).pipe(delay(8000)), of(false).pipe(delay(8000 + 15000)));
                    }
                    return of(false);
                }),
            ),
            //8 seconds after the hiding the split times overlay
            this.splitTimesOverlayStrategy.updates.pipe(
                map(overlays => overlays.includes(Overlay.SplitTimes)),
                distinctUntilChanged(),
                filter(includes => includes),
                switchMap(() => {
                    return merge(of(true).pipe(delay(28000)), of(false).pipe(delay(28000 + 15000)));
                }),
            ),
        ).pipe(
            distinctUntilChanged(),
            map(shouldShow => (shouldShow ? [Overlay.StrokeRates] : [])),
            shareReplay(1),
        );
    }

    constructor(private splitTimesOverlayStrategy: OverlayStrategy, private startSignalProvider: StartSignalProvider) {}
}
