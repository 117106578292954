import {Presenter} from '../../support/with_presenter';
import {Race} from '../../models/race';
import {Subscription} from 'rxjs';
import {observable} from 'mobx';
import {RaceProvider} from '../../business/race/race_provider';

export class BoatsOverviewScreenPresenter implements Presenter {
    @observable
    public race: Race;

    private subscription: Subscription | undefined;

    constructor(private initialRace: Race, private raceProvider: RaceProvider) {
        this.race = initialRace;
    }

    public mount(): void {
        this.subscription = this.raceProvider.get().subscribe(raceTry => {
            raceTry.map(race => (this.race = race));
        });
    }

    public unmount(): void {
        if (this.subscription !== undefined) {
            this.subscription.unsubscribe();
        }
    }
}
