import {BehaviorSubject, Observable} from 'rxjs';
import {Boat} from '../models/boat';
import {CreateBoatRepository} from '../ui/boats_overview_screen/boats_grid/internal/create_boat_interactor';
import {BoatByNameRepository} from '../ui/boats_overview_screen/boats_grid/internal/next_boat_name_provider';
import {Option} from '../support/monads/option';
import {BoatsRepository as ApiBoatsRepository} from '../business/boats/api_boats_provider';
import {BoatsRepository as SocketBoatsRepository} from '../business/boats/socket_boats_provider';
import {BoatsRepository as LocalStorageBoatsRepository} from '../business/boats/local_storage_boats_provider';

export interface BoatsRepository
    extends ApiBoatsRepository,
        SocketBoatsRepository,
        LocalStorageBoatsRepository,
        CreateBoatRepository,
        BoatByNameRepository {}

export class DefaultBoatsRepository implements BoatsRepository {
    private subject = new BehaviorSubject<Boat[]>([]);

    public stream(): Observable<Boat[]> {
        return this.subject;
    }

    public replace(boats: Boat[]): void {
        this.subject.next(boats);
    }

    public createOrUpdate(boat: Boat): void {
        this.subject.next([...this.subject.value.filter(b => b.id !== boat.id), boat]);
    }

    public async getByName(name: string): Promise<Option<Boat>> {
        const result = this.subject.value.find(boat => boat.name === name);

        return result === undefined ? Option.none() : Option.some(result);
    }
}
