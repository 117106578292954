import * as React from 'react';
import {FunctionComponent, useState} from 'react';
import styled from 'styled-components';
import {Block} from '../../../../ui_mobile/components/block';

interface OwnProps {
    disabled: boolean;
    initialDistance?: number;
    distanceMeters: number;
    onChange: (distanceMeters: number) => void;
}

const DistanceInput = styled.input`
    text-align: center;
    width: calc(100% - 3rem);
    height: 3rem;
    margin: 0;
    padding: 1rem;
    overflow: hidden;
    background: transparent;
    border: none;
    border-bottom: 0.25rem solid #f0f0f0;

    font-size: 2rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: inherit;

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    [type='number'] {
        -moz-appearance: textfield; /* Firefox */
    }
`;

const Button = styled.button`
    width: 3rem;
    height: 3.25rem;
    outline: none !important;
    font-size: 2rem;
    background: #f0f0f0;
    border: none;
    color: #00c9ff;
    border-bottom: 0.25rem solid #f0f0f0;
`;

const FormBlock = styled(Block)`
    padding: 0;
    overflow: hidden;

    form {
        width: 100%;
    }
`;

export const DistanceBlockForm: FunctionComponent<OwnProps> = ({
    distanceMeters,
    initialDistance,
    disabled,
    onChange,
}) => {
    const [state, setState] = useState<number>(initialDistance || 100);

    return (
        <FormBlock>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    onChange(typeof state !== 'string' ? state : 100);
                }}>
                <DistanceInput
                    type="number"
                    disabled={disabled}
                    value={state || ''}
                    onChange={e => setState(parseInt(e.target.value, 10))}
                    min={1}
                />
                <Button disabled={disabled}>
                    <span className="ion-md-checkmark"></span>
                </Button>
            </form>
        </FormBlock>
    );
};
