import * as React from 'react';

interface State {
    image: undefined | HTMLImageElement;
    status: string;
}

const defaultState = {image: undefined, status: 'loading'};

/**
 * @link https://github.com/konvajs/use-image
 * @param url
 */
export function useImage(url: string): [undefined | HTMLImageElement, string] {
    const [state, setState] = React.useState<State>(defaultState);

    React.useEffect(
        function() {
            if (!url) return;
            const img: HTMLImageElement = document.createElement('img');

            function onload() {
                setState({image: img, status: 'loaded'});
            }

            function onerror() {
                setState({image: undefined, status: 'failed'});
            }

            img.addEventListener('load', onload);
            img.addEventListener('error', onerror);
            img.src = url;

            return function cleanup() {
                img.removeEventListener('load', onload);
                img.removeEventListener('error', onerror);
                setState(defaultState);
            };
        },
        [url],
    );

    // return array because it it better to use in case of several useImage hooks
    // const [background, backgroundStatus] = useImage(url1);
    // const [patter] = useImage(url2);
    return [state.image, state.status];
}
