import * as React from 'react';
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react-lite';
import {PresenterProps, withPresenter} from '../../../../support/with_presenter';
import {DraggableType} from '../boats_grid_drag_handler_presenter';
import {DraggableBlockItem} from './draggable_block_item';
import {DraggableParticipantBlockPresenter} from './draggable_participant_block_presenter';
import {Participant} from '../../../../models/participant';
import {ParticipantEditModal} from '../participants_block/participant_edit_modal/participant_edit_modal';
import {styled} from '../../../theme';
import {Race} from '../../../../models/race';
import {isAuthorized} from '../../../../business/permissions/is_authorized';
import {Permission} from '../../../../enumerations/permission';
import {BlockItem} from './block_item';

interface OwnProps {
    index: number;
    participant: Participant;
    race: Race;
}

const NameButton = styled.button`
    border: none;
    background: none;
    color: inherit;
    font-weight: inherit;
`;

const DraggableParticipantBlockComponent: FunctionComponent<OwnProps &
    PresenterProps<DraggableParticipantBlockPresenter>> = observer(({presenter, participant, race, index}) => {
    const {isEditModalOpen, onEditClick, closeEditModal} = presenter;

    if (!isAuthorized(Permission.EDIT_PARTICIPANT, race)) {
        return <BlockItem>{participant.name}</BlockItem>;
    }

    return (
        <>
            {isEditModalOpen && (
                <ParticipantEditModal isOpen={isEditModalOpen} onClose={closeEditModal} participant={participant} />
            )}
            <DraggableBlockItem index={index} id={participant.id} type={DraggableType.Participant}>
                <NameButton onClick={onEditClick}>{participant.name}</NameButton>
            </DraggableBlockItem>
        </>
    );
});

export const DraggableParticipantBlock = withPresenter<DraggableParticipantBlockPresenter, OwnProps>(
    (props, component) => new DraggableParticipantBlockPresenter(),
    DraggableParticipantBlockComponent,
);
