import * as React from 'react';
import {FunctionComponent} from 'react';
import {Background} from '../../components/background';
import {observer} from 'mobx-react-lite';
import {BoatBlock} from './boat_block';
import {LargeButton} from '../../../ui_mobile/components/form/button';
import {Leader} from '../../components/text';
import {Heading1} from '../../components/heading';
import {BoatsScreenPresenter} from './boats_screen_presenter';
import {Race} from '../../../models/race';
import {withPresenter, PresenterProps} from '../../../support/with_presenter';
import {DefaultBoatsWithCombinationsProvider} from '../../../business/boats/boats_with_combinations_provider';
import {DefaultStartTimeProvider} from '../../../business/race/start_time_provider';
import {SocketBoatsProgressProvider} from '../../../business/boats/boats_progress_provider';
import {DefaultBoatProgressWithCombinationsProvider} from '../../../business/boats/boats_with_progress_and_combinations_provider';

interface OwnProps {
    race: Race;
}

export const BoatsScreenComponent: FunctionComponent<OwnProps & PresenterProps<BoatsScreenPresenter>> = observer(
    ({presenter, race}) => {
        return (
            <>
                <Background />
                <div className="container align-self-start">
                    <div className="row">
                        <div className="col-12 text-center">
                            <Heading1>Connected participants</Heading1>
                            <Leader>
                                In the overview below you can see the connected participants. Enter the room code as
                                shown above to join.
                            </Leader>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {presenter.boatCombinations.length === 0 && (
                                <Leader>There are no participants in this race yet!</Leader>
                            )}
                            {presenter.boatCombinations.map(boatCombination => (
                                <BoatBlock key={boatCombination.boat.id} boatWithCombinations={boatCombination} />
                            ))}
                        </div>
                    </div>

                    <div className="col-10 offset-1 col-md-6 offset-md-3">
                        <LargeButton
                            to={`/${race.id}/race-overview`}
                            disabled={presenter.boatCombinations.length === 0}>
                            Race screen
                            <span className="ion-ios-arrow-forward" />
                        </LargeButton>
                        <LargeButton to={`/${race.id}/lobby`} disabled={false} color="blue">
                            <span className="ion-ios-arrow-back" />
                            Previous screen
                        </LargeButton>
                        <LargeButton to="/" disabled={false} color="darkBlue">
                            Leave race
                            <span className="ion-md-exit" />
                        </LargeButton>
                    </div>
                </div>
            </>
        );
    },
);

export const BoatsScreen = withPresenter<BoatsScreenPresenter, OwnProps>((props, component) => {
    const boatsWithCombinationsProvider = new DefaultBoatsWithCombinationsProvider(
        component.boatsProviderFactory.create(props.race),
        component.participantsProviderFactory.create(props.race),
        component.devicesProviderFactory.create(props.race),
    );
    const startTimeProvider = new DefaultStartTimeProvider(
        props.race,
        component.raceProviderFactory.create(props.race),
    );
    const boatsProgressProvider = new SocketBoatsProgressProvider(
        props.race.id,
        startTimeProvider,
        boatsWithCombinationsProvider,
        component.networkComponent.boatsProgressSocket,
    );

    const boatProgressWithCombinationsProvider = new DefaultBoatProgressWithCombinationsProvider(
        boatsProgressProvider,
        component.participantsProviderFactory.create(props.race),
        component.devicesProviderFactory.create(props.race),
    );

    return new BoatsScreenPresenter(boatProgressWithCombinationsProvider);
}, BoatsScreenComponent);
