import {BoatProgress} from '../../../business/boats/boats_progress_provider';
import {Race} from '../../../models/race';
import {getFarthestBoat} from './boat_selectors';
import {getBiggestBoatHeight} from './image_data';

/**
 * The number of pixels needed to fit boats
 * @param boats
 * @param race
 * @param imageSizeMultiplier
 */
export function calculateMarginToFitBoatsPx(boats: readonly BoatProgress[], race: Race, imageSizeMultiplier: number) {
    const farthestBoat = getFarthestBoat(boats);
    const biggestBoatHeight = getBiggestBoatHeight(boats);

    if (farthestBoat !== null) {
        //In the first and last 50 meters we want the entire boats in view
        return farthestBoat.distanceMeters < 50 || race.distanceMeters - farthestBoat.distanceMeters < 50
            ? biggestBoatHeight * imageSizeMultiplier
            : (biggestBoatHeight * imageSizeMultiplier) / 2;
    }

    return biggestBoatHeight * imageSizeMultiplier;
}
