import {Presenter} from '../../../support/with_presenter';
import {observable} from 'mobx';
import {Try} from '../../../support/monads/try';
import {Race} from '../../../models/race';
import {toast} from 'react-toastify';

export interface RaceStarter {
    start(): Promise<Try<Race>>;
}

export interface RaceAborter {
    abort(): Promise<Try<Race>>;
}

export class RaceControlButtonPresenter implements Presenter {
    @observable
    public busy = false;

    constructor(private raceStarter: RaceStarter, private raceAborter: RaceAborter) {}

    public mount(): void {
        /* Noop */
    }

    public unmount(): void {
        /* Noop */
    }

    public async onStartClick() {
        this.busy = true;
        (await this.raceStarter.start()).fold(
            () => {
                this.busy = false;
            },
            () => {
                this.busy = false;
                toast('Something went wrong while requesting a start, please check your setup and try again.', {
                    type: toast.TYPE.ERROR,
                });
            },
        );
    }

    public async onAbortClick() {
        this.busy = true;
        (await this.raceAborter.abort()).fold(
            () => {
                this.busy = false;
            },
            () => {
                this.busy = false;
                toast('Something went wrong while aborting the race, please check your setup and try again.', {
                    type: toast.TYPE.ERROR,
                });
            },
        );
    }
}
