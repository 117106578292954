import {Presenter} from '../../support/with_presenter';
import {Race} from '../../models/race';
import {Try} from '../../support/monads/try';
import {observable} from 'mobx';
import {toast} from 'react-toastify';
import {NavigateFn} from '@reach/router';

export interface RaceDistanceUpdater {
    update(distanceMeters: number): Promise<Try<Race>>;
}

export class SelectDistanceFormPresenter implements Presenter {
    @observable
    public busy = false;

    constructor(
        private raceId: string,
        private navigate: NavigateFn,
        private raceDistanceUpdater: RaceDistanceUpdater,
    ) {}

    public mount(): void {
        /* Noop */
    }

    public unmount(): void {
        /* Noop */
    }

    public async onDistanceClick(distanceMeters: number) {
        this.busy = true;
        (await this.raceDistanceUpdater.update(distanceMeters)).fold(
            () => {
                /* Noop */
            },
            () => {
                toast('Something went wrong while updating race distance, please try again.', {
                    type: toast.TYPE.ERROR,
                });
            },
        );
        this.busy = false;
    }

    public async onDistanceNavigate(distanceMeters: number) {
        this.busy = true;
        (await this.raceDistanceUpdater.update(distanceMeters)).fold(
            () => {
                this.navigate(`/${this.raceId}/boats`);
            },
            () => {
                toast('Something went wrong while updating race distance, please try again.', {
                    type: toast.TYPE.ERROR,
                });
            },
        );
        this.busy = false;
    }
}
