import * as React from 'react';
import {FunctionComponent} from 'react';
import {Row} from '../../../../components/row';
import {Col} from '../../../../components/col';
import {Droppable} from 'react-beautiful-dnd';
import {AddParticipantForm} from './add_participant_form/add_participant_form';
import {Block} from '../components/block';
import {Race} from '../../../../models/race';
import {DraggableType} from '../boats_grid_drag_handler_presenter';
import {PresenterProps, withPresenter} from '../../../../support/with_presenter';
import {ParticipantsBlockPresenter} from './participants_block_presenter';
import {observer} from 'mobx-react-lite';
import {DefaultParticipantsWithoutBoatProvider} from './internal/participants_without_boat_provider';
import styled from 'styled-components';
import {BlockItem} from '../components/block_item';
import {DraggableParticipantBlock} from '../components/draggable_participant_block';
import {Permission} from '../../../../enumerations/permission';
import {Can} from '../../../components/can';

interface OwnProps {
    race: Race;
}

interface OwnProps {
    race: Race;
}

const ListWrapper = styled.div`
    > div {
        margin-right: 10px;
    }
`;

const ParticipantsBlockComponent: FunctionComponent<OwnProps & PresenterProps<ParticipantsBlockPresenter>> = observer(
    ({race, presenter}) => {
        const {participants} = presenter;

        return (
            <>
                <Block title="Participants" icon="ion-md-people">
                    <Row>
                        <Col numCols={12}>
                            <Droppable droppableId="participants-overview" type={DraggableType.Participant}>
                                {provided => (
                                    <ListWrapper
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className="d-flex flex-row flex-wrap justify-content-start">
                                        <Can permission={Permission.EDIT_PARTICIPANTS} race={race}>
                                            <BlockItem inactive>Reset participant</BlockItem>
                                        </Can>
                                        {participants.map((participant, index) => (
                                            <DraggableParticipantBlock
                                                key={participant.id}
                                                index={index}
                                                participant={participant}
                                                race={race}
                                            />
                                        ))}
                                        {provided.placeholder}
                                    </ListWrapper>
                                )}
                            </Droppable>
                        </Col>
                    </Row>
                    <Can permission={Permission.CREATE_PARTICIPANT} race={race}>
                        <Row>
                            <Col numCols={12}>
                                <AddParticipantForm race={race} />
                            </Col>
                        </Row>
                    </Can>
                </Block>
            </>
        );
    },
);

export const ParticipantsBlock = withPresenter<ParticipantsBlockPresenter, OwnProps>(
    (props, component) =>
        new ParticipantsBlockPresenter(
            new DefaultParticipantsWithoutBoatProvider(component.participantsProviderFactory.create(props.race)),
        ),
    ParticipantsBlockComponent,
);
