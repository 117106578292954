import * as React from 'react';
import {FunctionComponent} from 'react';
import {yPositionIsInViewport} from '../calculators/position_in_viewport_calculator';
import {Group, Rect} from 'react-konva';
import {Label} from './label';
import {formatDistanceMeters} from '../../../support/format_distance';

interface OwnProps {
    height: number;
    viewportOffsetPx: number;
    meterInPx: number;
    canvasWidth: number;
    canvasHeight: number;
    marginTopPx: number;
    distanceMeters: number;
}

interface SplitLine {
    distanceMeters: number;
    y: number;
}

export const SplitLines: FunctionComponent<OwnProps> = ({
    height,
    viewportOffsetPx,
    meterInPx,
    canvasWidth,
    canvasHeight,
    distanceMeters,
}) => {
    const splitLines: SplitLine[] = [];
    let locationMeters = 0;
    const intervalMeters = 500;
    do {
        const splitLineY = canvasHeight - locationMeters * meterInPx;
        if (locationMeters !== 0 && yPositionIsInViewport(splitLineY, canvasHeight, viewportOffsetPx, 200)) {
            splitLines.push({
                distanceMeters: locationMeters,
                y: splitLineY,
            });
        }
        locationMeters += intervalMeters;
    } while (locationMeters < distanceMeters);

    return (
        <>
            {splitLines.map(splitLine => {
                return (
                    <React.Fragment key={splitLine.distanceMeters}>
                        <Group x={(canvasWidth - 270) / 2} y={splitLine.y - 70}>
                            <Rect x={0} y={0} fill="#193F49" width={270} height={56} cornerRadius={5} />
                            <Label text={formatDistanceMeters(splitLine.distanceMeters)} />
                        </Group>
                        <Rect
                            key={splitLine.distanceMeters}
                            y={splitLine.y}
                            width={canvasWidth}
                            height={height}
                            fill="#193F49"
                        />
                    </React.Fragment>
                );
            })}
        </>
    );
};
