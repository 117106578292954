import {Observable} from 'rxjs';
import {Try} from '../../support/monads/try';
import {map, tap, shareReplay} from 'rxjs/operators';
import {StartResult} from '../../models/start_result';

export interface StartResultProvider {
    get(): Observable<Try<StartResult>>;
}

export interface StartResultRepository {
    get(): Observable<StartResult | null>;

    update(startResult: StartResult): void;
}

export interface StartResultForRaceIdSocket {
    getForRaceId(raceId: string): Observable<StartResult>;
}

export class SocketStartResultProvider implements StartResultProvider {
    protected observable = this.startResultForRaceIdSocket.getForRaceId(this.raceId).pipe(
        tap(startResult => this.startResultRepository.update(startResult)),
        map(startResult => Try.just(startResult)),
        shareReplay(1),
    );

    constructor(
        private raceId: string,
        private startResultForRaceIdSocket: StartResultForRaceIdSocket,
        private startResultRepository: StartResultRepository,
    ) {}

    public get(): Observable<Try<StartResult>> {
        return this.observable;
    }
}
