import {Observable} from 'rxjs';
import {useEffect, useState} from 'react';

export function useObservable<T>(observable: Observable<T>, deps: unknown[] = []): T | null {
    const [state, setState] = useState<T | null>(null);

    useEffect(() => {
        const subscription = observable.subscribe(value => setState(value));
        return () => subscription.unsubscribe();
    }, deps);

    return state;
}
