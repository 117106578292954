import {Presenter} from '../../support/with_presenter';
import {Race} from '../../models/race';
import {observable} from 'mobx';
import {RaceProvider} from '../../business/race/race_provider';
import {CompositeSubscription} from '../../support/composite_subscription';

export class RaceOverviewScreenPresenter implements Presenter {
    @observable
    public race: Race;

    private subscriptions = new CompositeSubscription();

    constructor(initialRace: Race, private raceProvider: RaceProvider) {
        this.race = initialRace;
    }

    public mount(): void {
        this.subscriptions.add(
            this.raceProvider.get().subscribe(raceTry => {
                raceTry.map(race => (this.race = race));
            }),
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }
}
