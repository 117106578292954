import {BoatsProvider} from './boats_provider';
import {Observable} from 'rxjs';
import {Try} from '../../support/monads/try';
import {Boat} from '../../models/boat';
import {map} from 'rxjs/operators';

export interface BoatsRepository {
    stream(): Observable<Boat[]>;
}

export class LocalStorageBoatsProvider implements BoatsProvider {
    constructor(private boatRepository: BoatsRepository) {}

    public get(): Observable<Try<Boat[]>> {
        return this.boatRepository.stream().pipe(map(boats => Try.just(boats)));
    }
}
