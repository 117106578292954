import {from, Observable} from 'rxjs';
import {Try} from '../../support/monads/try';
import {Boat} from '../../models/boat';
import {first, tap, shareReplay} from 'rxjs/operators';
import {BoatsProvider} from './boats_provider';

export interface BoatsForRaceApi {
    getForRaceId(raceId: string): Promise<Try<Boat[]>>;
}

export interface BoatsRepository {
    replace(boats: Boat[]): void;
}

export class ApiBoatsProvider implements BoatsProvider {
    private observable = from(this.boatsForRaceApi.getForRaceId(this.raceId)).pipe(
        first(),
        tap(boatTry => boatTry.map(value => this.boatsRepository.replace(value))),
        shareReplay(1),
    );

    constructor(
        private raceId: string,
        private boatsForRaceApi: BoatsForRaceApi,
        private boatsRepository: BoatsRepository,
    ) {}

    public get(): Observable<Try<Boat[]>> {
        return this.observable;
    }
}
