import {ParticipantToBoatAdder} from '../boats_grid_drag_handler_presenter';
import {Participant} from '../../../../models/participant';
import {Try} from '../../../../support/monads/try';
import {Option} from '../../../../support/monads/option';

export interface UpdateParticipantRepository {
    byId(id: string): Promise<Option<Participant>>;
    createOrUpdate(participant: Participant): void;
}

export interface UpdateParticipantApi {
    update(id: string, boatId: string | null, name: string, weightGrams: number | null): Promise<Try<Participant>>;
}

export class ApiParticipantToBoatAdder implements ParticipantToBoatAdder {
    constructor(
        private updateParticipantRepository: UpdateParticipantRepository,
        private updateParticipantApi: UpdateParticipantApi,
    ) {}

    public async addToBoat(participantId: string, boatId: string | null): Promise<Try<Participant>> {
        const participantOption = await this.updateParticipantRepository.byId(participantId);

        return participantOption.fold<Promise<Try<Participant>>>(
            async originalParticipant => {
                this.updateEnthusiastically(originalParticipant, boatId);

                const participantTry = await this.updateParticipantApi.update(
                    originalParticipant.id,
                    boatId,
                    originalParticipant.name,
                    originalParticipant.weightGrams,
                );
                return this.writeOrRevert(participantTry, originalParticipant);
            },
            async () => Try.raiseError(new Error('Trying to update unknown participant')),
        );
    }

    private writeOrRevert(participantTry: Try<Participant>, originalParticipant: Participant) {
        return participantTry.fold(
            participant => {
                this.updateParticipantRepository.createOrUpdate(participant);
                return Try.just(participant);
            },
            e => {
                this.updateParticipantRepository.createOrUpdate(originalParticipant);
                return Try.raiseError(e);
            },
        );
    }

    private updateEnthusiastically(originalParticipant: Participant, boatId: string | null) {
        this.updateParticipantRepository.createOrUpdate({
            ...originalParticipant,
            boatId,
        });
    }
}
