import {Presenter} from '../../../../support/with_presenter';
import {observable} from 'mobx';

export class DraggableParticipantBlockPresenter implements Presenter {
    @observable
    public isEditModalOpen = false;

    public mount(): void {
        /* Noop */
    }

    public unmount(): void {
        /* Noop */
    }

    public onEditClick = () => {
        this.isEditModalOpen = true;
    };

    public closeEditModal = () => {
        this.isEditModalOpen = false;
    };
}
