import * as React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter} from '../../components/modal';
import {withPresenter, PresenterProps} from '../../../support/with_presenter';
import {BoatEditModalPresenter} from './boat_edit_modal_presenter';
import {Boat} from '../../../models/boat';
import {observer} from 'mobx-react-lite';
import {ApiBoatUpdater} from './internal/boat_updater';
import {BoatType, boatTypeToString} from '../../../models/boat_type';
import {SmallButton} from '../../components/button';

interface OwnProps {
    isOpen: boolean;
    onClose: () => void;
    boat: Boat;
}

const BoatEditModalComponent: React.FunctionComponent<OwnProps & PresenterProps<BoatEditModalPresenter>> = observer(
    ({isOpen, onClose, presenter}) => {
        const {boat, onBoatNameChange, onBoatTypeChange, onSave} = presenter;

        return (
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalHeader>
                    <h5>Edit "{boat.name}"</h5>
                    <button type="button" className="close" aria-label="Close" onClick={onClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </ModalHeader>
                <form
                    onSubmit={e => {
                        e.preventDefault();
                        onSave()
                            .then(() => onClose())
                            .catch(e => console.error(e));
                    }}>
                    <ModalBody>
                        <div className="form-group">
                            <label htmlFor={'boat-name-' + boat.id}>Boat name</label>
                            <input
                                type="text"
                                className="form-control"
                                id={'boat-name-' + boat.id}
                                value={boat.name}
                                onChange={e => onBoatNameChange(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor={'boat-type-' + boat.id}>Boat type</label>
                            <select
                                className="form-control"
                                id={'boat-type-' + boat.id}
                                onChange={e => onBoatTypeChange(e.target.value as BoatType | 'none')}
                                value={boat.type || 'none'}>
                                <option key="none" value="none">
                                    None
                                </option>
                                {Object.values(BoatType).map(boatType => (
                                    <option key={boatType} value={boatType}>
                                        {boatTypeToString(boatType)}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <SmallButton type="button" className="btn btn-secondary" onClick={onClose}>
                            Close
                        </SmallButton>
                        <SmallButton type="submit" className="btn btn-primary">
                            Save changes
                        </SmallButton>
                    </ModalFooter>
                </form>
            </Modal>
        );
    },
);

export const BoatEditModal = withPresenter<BoatEditModalPresenter, OwnProps>(
    (props, component) =>
        new BoatEditModalPresenter(props.boat, new ApiBoatUpdater(component.networkComponent.boatsApi)),
    BoatEditModalComponent,
);
