// styled-components.ts
import * as styledComponents from 'styled-components';
import {ThemedStyledComponentsModule} from 'styled-components';

export interface Theme {}

export interface ThemeProps {
    theme: Theme;
}

export const DefaultTheme: Theme = {};

const {default: styled, css, keyframes, ThemeProvider} = styledComponents as ThemedStyledComponentsModule<Theme>;

export {css, keyframes, ThemeProvider, styled};
