interface Subscription {
    unsubscribe(): void;
}

export class CompositeSubscription {
    private _list: Subscription[] = [];

    public add(...subscriptions: Subscription[]) {
        this._list = this._list.concat(subscriptions);
    }

    public clear() {
        this._list.forEach(subscription => {
            subscription.unsubscribe();
        });

        this._list = [];
    }
}
