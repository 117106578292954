import {BoatProgress} from '../../../business/boats/boats_progress_provider';

export function getFarthestBoat(boats: readonly BoatProgress[]) {
    return boats.reduce<BoatProgress | null>((acc, boat) => {
        if (acc === null || boat.distanceMeters > acc.distanceMeters) {
            return boat;
        }
        return acc;
    }, null);
}
