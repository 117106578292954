import * as React from 'react';
import {FunctionComponent, memo} from 'react';
import {Lane} from '../internal/lanes_provider';
import {Rect} from 'react-konva';

interface OwnProps {
    lanes: readonly Lane[];
    height: number;
    width: number;
}

interface LaneMarkerOwnProps {
    height: number;
    x: number;
}

const laneMarkerWidth = 2;

export const LaneMarker: FunctionComponent<LaneMarkerOwnProps> = ({x, height}) => {
    return (
        <Rect
            x={x}
            y={0}
            width={laneMarkerWidth}
            height={height}
            fillLinearGradientColorStops={[
                0,
                'rgba(255, 255, 255, 0.7)',
                0.4,
                'rgba(255, 255, 255,0.7)',
                1,
                'rgba(255, 255, 255,.15)',
            ]}
            fillLinearGradientStartPointY={0}
            fillLinearGradientEndPointY={height}
        />
    );
};

export const Lanes: FunctionComponent<OwnProps> = memo(({lanes, height, width}) => {
    if (lanes.length === 0) {
        return null;
    }
    const referenceLane = lanes[0];
    const firstLane = lanes[0];
    const lastLane = lanes[lanes.length - 1];

    const totalLanesWidth = lanes.reduce((sum, lane) => sum + lane.width, 0);
    const numSpacingLanes = Math.ceil((width - totalLanesWidth) / (2 * firstLane.width));

    return (
        <>
            {Array.from({length: numSpacingLanes}).map((_, index) => (
                <LaneMarker key={'left-' + index} x={firstLane.x - referenceLane.width * (index + 1)} height={height} />
            ))}
            {[...lanes].map(lane => (
                <LaneMarker key={'lane-' + lane.x} x={lane.x} height={height} />
            ))}
            {Array.from({length: numSpacingLanes}).map((_, index) => (
                <LaneMarker key={'right-' + index} height={height} x={lastLane.x + referenceLane.width * (index + 1)} />
            ))}
            <Rect
                key="bg-1"
                width={width}
                height={height}
                fillLinearGradientColorStops={[0, 'rgba(0,0,0, 0)', 0.6, 'rgba(0,0,0,.05)', 1, 'rgba(0,0,0,.35)']}
                fillLinearGradientStartPointY={0}
                fillLinearGradientEndPointY={height}
            />
            <Rect
                key="bg-2"
                x={0}
                width={firstLane.x}
                height={height}
                fillLinearGradientColorStops={[0, 'rgba(0,0,0, 0.25)', 1, 'rgba(0,0,0,.00)']}
                fillLinearGradientStartPointX={firstLane.x}
                fillLinearGradientEndPointX={0}
            />
            <Rect
                key="bg-3"
                x={lastLane.x + lastLane.width + laneMarkerWidth}
                width={width - lastLane.x + lastLane.width + laneMarkerWidth}
                height={height}
                fillLinearGradientColorStops={[1, 'rgba(0,0,0, 0.25)', 0, 'rgba(0,0,0,.00)']}
                fillLinearGradientStartPointX={width - lastLane.x + lastLane.width + laneMarkerWidth}
                fillLinearGradientEndPointX={0}
            />
        </>
    );
});
