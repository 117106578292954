import {leftPad} from './left_pad';

export function formatTimeMs(timeMs: number): string {
    return formatTimeSeconds(timeMs / 1000);
}

export function formatTimeSeconds(timeSeconds: number, lowPrecision = false): string {
    let wholeSeconds = Math.floor(timeSeconds);

    const fraction = timeSeconds - wholeSeconds;
    let roundedFraction = Math.round(fraction * 100);
    if (roundedFraction >= 100) {
        roundedFraction -= 100;
        wholeSeconds += 1;
    }

    const wholeMinutes = Math.floor(wholeSeconds / 60);
    const secondsLeft = wholeSeconds - wholeMinutes * 60;

    const secondsString = leftPad(secondsLeft, 2, '0');

    if (lowPrecision) {
        return `${wholeMinutes}:${secondsString}`;
    }
    const fractionString = leftPad(roundedFraction, 2, '0');

    return `${wholeMinutes}:${secondsString}.${fractionString}`;
}

export function formatTimeMsLowPrecision(timeSeconds: number): string {
    return formatTimeSeconds(timeSeconds / 1000, true);
}

export function formatTimeSecondsLowPrecision(timeSeconds: number): string {
    return formatTimeSeconds(timeSeconds, true);
}
