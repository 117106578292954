import {OverlayStrategy} from '../strategy_based_active_overlays_provider';
import {Overlay} from '../../overlays_presenter';
import {Observable} from 'rxjs';
import {StartTimeProvider} from '../../../../../business/race/start_time_provider';
import {map, startWith} from 'rxjs/operators';

export class StartListOverlayStrategy implements OverlayStrategy {
    public updates: Observable<Overlay[]> = this.startTimeProvider.get().pipe(
        map(startTimeMillis => (startTimeMillis === null ? [Overlay.StartList] : [])),
        startWith([]),
    );

    constructor(private startTimeProvider: StartTimeProvider) {}
}
