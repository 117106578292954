import React from 'react';
import styled from 'styled-components';

export const ModalHeader: React.FunctionComponent = ({children}) => {
    return <ModalHeaderDiv className="modal-header">{children}</ModalHeaderDiv>;
};

export const ModalBody: React.FunctionComponent = ({children}) => {
    return <div className="modal-body">{children}</div>;
};

export const ModalFooter: React.FunctionComponent = ({children}) => {
    return <ModalFooterDiv className="modal-footer">{children}</ModalFooterDiv>;
};

const ModalBackdropDiv = styled.div`
    background: rgba(0, 0, 0, 0.5);
`;

const ModalContentDiv = styled.div`
    border: none;
`;

const ModalHeaderDiv = styled.div`
    border-bottom: 2px solid #f5f5f5;
    h3,
    h4,
    h5 {
        margin: 3px 0;
        font-size: 15px;
        font-weight: bold;
        text-transform: uppercase;
    }
`;

const ModalFooterDiv = styled.div`
    border-top: none;
    background: #f5f5f5;
`;

interface OwnProps {
    isOpen: boolean;
    onClose: () => void;
}

export const Modal: React.FunctionComponent<OwnProps> = ({children, isOpen, onClose}) => {
    return (
        <ModalBackdropDiv
            className={'modal fade ' + (isOpen ? 'show' : '')}
            tabIndex={-1}
            role="dialog"
            aria-hidden="true"
            style={{display: isOpen ? 'block' : 'none'}}
            onClick={onClose}>
            <div className="modal-dialog" role="document" onClick={e => e.stopPropagation()}>
                <ModalContentDiv className="modal-content">{children}</ModalContentDiv>
            </div>
        </ModalBackdropDiv>
    );
};
