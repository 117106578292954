import {Presenter} from '../../support/with_presenter';
import {Subscription} from 'rxjs';
import {observable} from 'mobx';
import {ToggleTokenSocket} from '../../network/toggle_token_socket';

export class ToggleTokenPresenter implements Presenter {
    @observable
    public isTokenVisible: boolean = false;

    private subscription: Subscription | undefined;

    constructor(private toggleTokenSocket: ToggleTokenSocket, private raceId: string) {}

    public mount(): void {
        this.subscription = this.toggleTokenSocket.getForRaceId(this.raceId).subscribe(data => {
            this.isTokenVisible = data.isTokenVisible;
        });
    }

    public unmount(): void {
        if (this.subscription !== undefined) {
            this.subscription.unsubscribe();
        }
    }

    public setIsTokenVisible = (isTokenVisible: boolean) => {
        this.toggleTokenSocket.toggleForRaceId(this.raceId, isTokenVisible);
    };
}
