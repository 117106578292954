import {BoatProgress} from '../../../business/boats/boats_progress_provider';
import {round} from '../support/round';

interface ViewportMetersRange {
    maxMetersInViewport: number;
    minMetersInViewport: number;
}

const defaultMetersInViewport: ViewportMetersRange = {
    maxMetersInViewport: 250,
    minMetersInViewport: 50,
};

const maximumTotalUseOfViewport = 0.75;

function getMetersTraveledByFastestBoat(boatProgresses: readonly BoatProgress[]): number {
    if (boatProgresses.length === 0) {
        return 0;
    }
    return Math.max(...boatProgresses.map(d => d.distanceMeters));
}

function getMetersTraveledBySlowestBoat(boatProgresses: readonly BoatProgress[]): number {
    if (boatProgresses.length === 0) {
        return 0;
    }
    return Math.min(...boatProgresses.map(d => d.distanceMeters));
}

function getBottomBoundaryInMeters(boats: readonly BoatProgress[], viewportMetersRange: ViewportMetersRange): number {
    if (boats.length === 0) {
        return 0;
    }
    const slowestBoatMeters = getMetersTraveledBySlowestBoat(boats);
    const fastestBoatMeters = getMetersTraveledByFastestBoat(boats);

    if (fastestBoatMeters - slowestBoatMeters > viewportMetersRange.maxMetersInViewport) {
        return fastestBoatMeters - viewportMetersRange.maxMetersInViewport;
    }
    return slowestBoatMeters;
}

export function calculateViewportOffsetPx(
    boatProgresses: readonly BoatProgress[],
    viewportHeightPx: number,
    marginTopPx: number,
    meterInPx: number,
    occupiedHeightPx: number,
    distanceMeters: number,
    viewportMetersRange = defaultMetersInViewport,
) {
    const metersTraveledByFastestBoat = getMetersTraveledByFastestBoat(boatProgresses);
    const bottomBoundaryInMeters = getBottomBoundaryInMeters(boatProgresses, viewportMetersRange);

    const metersToGoForLastBoat = distanceMeters - bottomBoundaryInMeters;
    if (metersToGoForLastBoat <= 0) {
        return distanceMeters * meterInPx - occupiedHeightPx;
    }

    // The offset we want to reach when rowing in steady state
    const targetOffset =
        metersTraveledByFastestBoat * meterInPx -
        viewportHeightPx +
        ((1 - maximumTotalUseOfViewport) / 2) * viewportHeightPx;

    const initialOffset = bottomBoundaryInMeters * meterInPx - marginTopPx;

    const distanceMetersToShowInitialOffset = 50;

    const factorDoneInitialOffset =
        1 -
        Math.max(0, distanceMetersToShowInitialOffset - metersTraveledByFastestBoat) /
            distanceMetersToShowInitialOffset;

    return Math.max(
        (1 - factorDoneInitialOffset) * initialOffset,
        factorDoneInitialOffset * targetOffset + (1 - factorDoneInitialOffset) * initialOffset,
    );
}

export function getMeterInPx(
    containerHeightPx: number,
    boatProgresses: readonly BoatProgress[],
    marginTopPx: number,
    viewportMetersRange = defaultMetersInViewport,
    multiplier = maximumTotalUseOfViewport,
): number {
    const applicableBoatProgresses = boatProgresses.filter(boatProgress => boatProgress.distanceMeters >= 0);
    if (applicableBoatProgresses.length === 0) {
        return (containerHeightPx / viewportMetersRange.minMetersInViewport) * multiplier;
    }

    const metersInViewportForBoats =
        (getMetersTraveledByFastestBoat(applicableBoatProgresses) -
            getBottomBoundaryInMeters(applicableBoatProgresses, viewportMetersRange)) *
        (1 + (1 - multiplier));

    const clippedMetersInViewport = Math.min(
        viewportMetersRange.maxMetersInViewport,
        Math.max(viewportMetersRange.minMetersInViewport, metersInViewportForBoats),
    );

    return round((containerHeightPx + marginTopPx) / clippedMetersInViewport, 1);
}
