import {auditTime, first} from 'rxjs/operators';
import {animationFrameScheduler, merge, Observable} from 'rxjs';

//Emits the first possible value, then throttles the observable by durations ms, and always emits last value
export function debounceThrottle<T>(obs: Observable<T>, duration: number): Observable<T> {
    return merge(obs.pipe(first()), obs.pipe(auditTime(duration)));
}

//Emits the first possible value, then throttles the observable until the next animation frame, and always emits last value
export function debounceThrottleAnimationFrame<T>(obs: Observable<T>, minDuration: number = 0): Observable<T> {
    return merge(obs.pipe(first()), obs.pipe(auditTime(minDuration, animationFrameScheduler)));
}
