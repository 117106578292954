import {RaceStarter} from '../race_control_button_presenter';
import {Race} from '../../../../models/race';
import {Try} from '../../../../support/monads/try';

export interface StartRaceApi {
    start(raceId: string): Promise<Try<Race>>;
}

export interface UpdateRaceRepository {
    update(race: Race): void;
}

export class ApiRaceStarter implements RaceStarter {
    constructor(
        private raceId: string,
        private startRaceApi: StartRaceApi,
        private updateRaceRepository: UpdateRaceRepository,
    ) {}

    public async start(): Promise<Try<Race>> {
        return (await this.startRaceApi.start(this.raceId)).map(race => {
            this.updateRaceRepository.update(race);
            return race;
        });
    }
}
