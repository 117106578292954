import * as React from 'react';
import {useEffect, useState} from 'react';
import {Lane} from '../internal/lanes_provider';
import {boatImagesData, boatImagesWidth, getBoatHeight} from '../support/image_data';
import {BoatProgress} from '../../../business/boats/boats_progress_provider';
import {Boat as BoatComp} from './boat';
import {NodeGroup} from 'react-move';
import {easeCubicOut} from 'd3-ease';

const boatFrontSvg = require('../boat_images/boat-top.svg');
const boatRowerSvg = require('../boat_images/boat-middle.svg');
const boatRearSvg = require('../boat_images/boat-bottom.svg');

//Each lane has one separator lying inside it, there should always be a forced width
const laneSeparatorWidthPx = 2;

interface OwnProps {
    boats: readonly BoatProgress[];
    lanes: readonly Lane[];
    distanceMeters: number;
    meterInPx: number;
    marginTopPx: number;
    canvasHeight: number;
    finishLineHeightPx: number;
    imageSizeMultiplier: number;
}

async function drawImageOnCanvas(
    ctx: CanvasRenderingContext2D,
    imageSrc: string,
    dx: number,
    dy: number,
    dw: number,
    dh: number,
) {
    return new Promise(resolve => {
        const boatFrontImage = new Image();
        boatFrontImage.src = imageSrc;
        boatFrontImage.onload = () => {
            ctx.clearRect(dx, dy, dw, dh);
            ctx.drawImage(boatFrontImage, dx, dy, dw, dh);
            resolve();
        };
    });
}

function calculateInitialBoatStyle(
    boatProgress: BoatProgress,
    lanes: readonly Lane[],
    distanceMeters: number,
    canvasHeightPx: number,
    meterInPx: number,
    finishLineHeight: number,
    imageSizeMultiplier: number,
) {
    const lane = lanes.find(l => l.boatId === boatProgress.id);

    const topPx =
        boatProgress.distanceMeters >= distanceMeters
            ? Math.floor(canvasHeightPx - distanceMeters * meterInPx) -
              finishLineHeight -
              getBoatHeight(boatProgress) * imageSizeMultiplier
            : Math.floor(canvasHeightPx - boatProgress.distanceMeters * meterInPx);

    return {
        top: topPx,
        x: lane ? lane.x : 0,
    };
}

function calculateBoatStyle(
    boatProgress: BoatProgress,
    lanes: readonly Lane[],
    distanceMeters: number,
    canvasHeightPx: number,
    meterInPx: number,
    finishLineHeight: number,
    imageSizeMultiplier: number,
) {
    const lane = lanes.find(l => l.boatId === boatProgress.id);

    const topPx =
        boatProgress.distanceMeters >= distanceMeters
            ? Math.floor(canvasHeightPx - distanceMeters * meterInPx) -
              finishLineHeight -
              getBoatHeight(boatProgress) * imageSizeMultiplier
            : Math.floor(canvasHeightPx - boatProgress.distanceMeters * meterInPx);

    return {
        top: [topPx],
        x: [lane ? lane.x : 0],
        timing: {duration: 2500, ease: easeCubicOut},
    };
}

export const AnimatingBoats: React.FunctionComponent<OwnProps> = ({
    boats,
    lanes,
    distanceMeters,
    meterInPx,
    canvasHeight,
    finishLineHeightPx,
    imageSizeMultiplier,
}) => {
    const [allImagesLoaded, setAllImagesLoaded] = useState(false);
    const [offscreenCanvas] = useState<HTMLCanvasElement>(() => document.createElement('canvas'));

    useEffect(() => {
        offscreenCanvas.height = 200 * imageSizeMultiplier;
        offscreenCanvas.width = imageSizeMultiplier * boatImagesWidth * 4;

        const ctx = offscreenCanvas.getContext('2d');
        if (ctx !== null) {
            ctx.clearRect(0, 0, offscreenCanvas.width, offscreenCanvas.height);
            Promise.all([
                drawImageOnCanvas(
                    ctx,
                    boatFrontSvg,
                    boatImagesData.front.x * imageSizeMultiplier + laneSeparatorWidthPx,
                    boatImagesData.front.y * imageSizeMultiplier,
                    boatImagesData.front.width * imageSizeMultiplier - laneSeparatorWidthPx,
                    boatImagesData.front.height * imageSizeMultiplier,
                ),
                drawImageOnCanvas(
                    ctx,
                    boatRowerSvg,
                    boatImagesData.rower.x * imageSizeMultiplier + laneSeparatorWidthPx,
                    boatImagesData.rower.y * imageSizeMultiplier,
                    boatImagesData.rower.width * imageSizeMultiplier - laneSeparatorWidthPx,
                    boatImagesData.rower.height * imageSizeMultiplier,
                ),
                drawImageOnCanvas(
                    ctx,
                    boatRearSvg,
                    boatImagesData.rear.x * imageSizeMultiplier + laneSeparatorWidthPx,
                    boatImagesData.rear.y * imageSizeMultiplier,
                    boatImagesData.rear.width * imageSizeMultiplier - laneSeparatorWidthPx,
                    boatImagesData.rear.height * imageSizeMultiplier,
                ),
            ]).then(() => {
                setAllImagesLoaded(true);
            });
        }
    }, [imageSizeMultiplier, offscreenCanvas]);

    if (!allImagesLoaded) {
        return null;
    }

    return (
        <NodeGroup
            data={boats as any[]}
            keyAccessor={d => d.id}
            start={boat =>
                calculateInitialBoatStyle(
                    boat,
                    lanes,
                    distanceMeters,
                    canvasHeight,
                    meterInPx,
                    finishLineHeightPx,
                    imageSizeMultiplier,
                )
            }
            update={boat =>
                calculateBoatStyle(
                    boat,
                    lanes,
                    distanceMeters,
                    canvasHeight,
                    meterInPx,
                    finishLineHeightPx,
                    imageSizeMultiplier,
                )
            }>
            {nodes => (
                <>
                    {nodes.map(({key, data, state}) => {
                        const {x, top} = state;
                        return (
                            <BoatComp
                                key={key}
                                x={x}
                                y={top}
                                participantCount={data.participantCount}
                                offscreenCanvas={offscreenCanvas}
                                imageSizeMultiplier={imageSizeMultiplier}
                            />
                        );
                    })}
                </>
            )}
        </NodeGroup>
    );
};
