import {Presenter} from '../../../../../support/with_presenter';
import {Observable, Subscription} from 'rxjs';
import {SplitTime} from '../../../../../models/split_time';
import {observable} from 'mobx';
import {BoatsProvider} from '../../../../../business/boats/boats_provider';
import {map, withLatestFrom} from 'rxjs/operators';
import {Try} from '../../../../../support/monads/try';
import {Boat} from '../../../../../models/boat';

export interface CollectedSplitTimesProvider {
    splitTimes: Observable<SplitTime[]>;
}

export interface SplitTimeViewModel {
    boatName: string;
    splitTimeMillis: number;
}

export class SplitTimesPresenter implements Presenter {
    @observable
    public splitTimes: SplitTimeViewModel[] = [];

    private subscription: Subscription | undefined;

    constructor(
        private collectedSplitTimesProvider: CollectedSplitTimesProvider,
        private boatsProvider: BoatsProvider,
    ) {}

    public mount(): void {
        this.subscription = this.collectedSplitTimesProvider.splitTimes
            .pipe(
                withLatestFrom(this.boatsProvider.get()),
                map(([splitTimes, boatsTry]: [SplitTime[], Try<Boat[]>]) =>
                    boatsTry.fold<SplitTimeViewModel[]>(
                        boats =>
                            splitTimes.map(splitTime => {
                                const boat = boats.find(boat => boat.id === splitTime.boatId);
                                return {
                                    boatName: boat !== undefined ? boat.name : 'N/A',
                                    splitTimeMillis: splitTime.splitTimeMillis,
                                };
                            }),
                        () => [],
                    ),
                ),
            )
            .subscribe((splitTimes: SplitTimeViewModel[]) => (this.splitTimes = splitTimes));
    }

    public unmount(): void {
        if (this.subscription !== undefined) {
            this.subscription.unsubscribe();
        }
    }
}
