import {ParticipantsProvider} from './participant_provider';
import {Observable} from 'rxjs';
import {Participant} from '../../models/participant';
import {Try} from '../../support/monads/try';
import {map} from 'rxjs/operators';

export interface ParticipantsRepository {
    stream(): Observable<Participant[]>;
}

export class LocalStorageParticipantProvider implements ParticipantsProvider {
    constructor(private participantRepository: ParticipantsRepository) {}

    get(): Observable<Try<Participant[]>> {
        return this.participantRepository.stream().pipe(map(participants => Try.just(participants)));
    }
}
