import {Presenter} from '../../support/with_presenter';
import {observable} from 'mobx';
import {BoatProgress, BoatsProgressProvider} from '../../business/boats/boats_progress_provider';
import {LanesProvider} from './internal/lanes_provider';
import {CompositeSubscription} from '../../support/composite_subscription';
import {ViewPortSizeProvider} from './support/viewport_sizes';
import {debounceThrottleAnimationFrame} from '../../support/rx_debounce_throttle';
import {BoatsScreenComponent} from './boats_screen_component';

export class BoatsScreenPresenter implements Presenter {
    @observable public boats: readonly BoatProgress[] | null = null;
    @observable public distanceMeters = 500;

    private _subscriptions = new CompositeSubscription();

    constructor(
        distanceMeters: number,
        private viewPortSizeProvider: ViewPortSizeProvider,
        private boatsProvider: BoatsProgressProvider,
        public lanesProvider: LanesProvider,
        public boatsScreenComponent: BoatsScreenComponent,
    ) {
        this.distanceMeters = distanceMeters;
    }

    public mount(): void {
        this._subscriptions.add(
            debounceThrottleAnimationFrame(this.boatsProvider.get(), 250).subscribe(boats => (this.boats = boats)),
        );

        this._subscriptions.add(
            this.boatsScreenComponent.collectedSplitTimesProvider.splitTimes.subscribe(() => {
                /* Noop, just make sure we're following the lifecycle of the boats screen */
            }),
        );
    }

    public unmount(): void {
        this._subscriptions.clear();
    }
}
