import * as React from 'react';
import {Rect} from 'react-konva';

interface OwnProps {
    y: number;
    canvasWidth: number;
    height: number;
}

export const StartLine: React.FunctionComponent<OwnProps> = React.memo(({y, canvasWidth, height}) => {
    return <Rect y={y - height} width={canvasWidth} height={height} fill="#193F49" />;
});
