import {Observable} from 'rxjs';
import {DevicesProvider} from '../../../../../business/devices/device_provider';
import {Try} from '../../../../../support/monads/try';
import {Device} from '../../../../../models/device';
import {map} from 'rxjs/operators';
import {DevicesWithoutParticipantProvider} from '../devices_block_presenter';

export class DefaultDevicesWithoutParticipantProvider implements DevicesWithoutParticipantProvider {
    constructor(private devicesProvider: DevicesProvider) {}

    public get(): Observable<Try<Device[]>> {
        return this.devicesProvider
            .get()
            .pipe(
                map(devicesTry => devicesTry.map(devices => devices.filter(device => device.participantId === null))),
            );
    }
}
