import {combineLatest, Observable} from 'rxjs';
import {Try} from '../../support/monads/try';
import {Boat} from '../../models/boat';
import {Participant} from '../../models/participant';
import {Device} from '../../models/device';
import {BoatsProvider} from './boats_provider';
import {ParticipantsProvider} from '../participants/participant_provider';
import {DevicesProvider} from '../devices/device_provider';
import {distinctUntilChanged, shareReplay} from 'rxjs/operators';

export interface BoatsWithCombinationsProvider {
    get(): Observable<Try<BoatWithCombinations[]>>;
}

export interface BoatWithCombinations {
    boat: Boat;
    combinations: ValidCombination[];
}

export interface ValidCombination {
    participant: Participant;
    device: Device;
}

export class DefaultBoatsWithCombinationsProvider implements BoatsWithCombinationsProvider {
    constructor(
        private boatsProvider: BoatsProvider,
        private participantsProvider: ParticipantsProvider,
        private deviceProvider: DevicesProvider,
    ) {}

    public get(): Observable<Try<BoatWithCombinations[]>> {
        return combineLatest(
            this.boatsProvider.get(),
            this.participantsProvider.get(),
            this.deviceProvider.get(),
            (boatsTry, participantsTry, devicesTry) =>
                boatsTry.flatMap(boats =>
                    participantsTry.flatMap(participants =>
                        devicesTry.map(devices => this.combine(boats, participants, devices)),
                    ),
                ),
        ).pipe(distinctUntilChanged(), shareReplay(1));
    }

    private combine(boats: Boat[], participants: Participant[], devices: Device[]): BoatWithCombinations[] {
        return boats
            .map(boat => {
                return {
                    boat: boat,
                    combinations: participants
                        .filter(participant => participant.boatId === boat.id)
                        .reduce((combinations: ValidCombination[], participant: Participant) => {
                            const device = devices.find(d => d.participantId === participant.id);
                            if (device !== undefined) {
                                combinations.push({
                                    participant,
                                    device,
                                });
                            }

                            return combinations;
                        }, []),
                };
            })
            .filter(boatWithCombinations => boatWithCombinations.combinations.length > 0);
    }
}
