import {Presenter} from '../../../../../support/with_presenter';
import {observable} from 'mobx';
import {BoatsProgressProvider} from '../../../../../business/boats/boats_progress_provider';
import {Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {debounceThrottleAnimationFrame} from '../../../../../support/rx_debounce_throttle';

export interface SpeedViewModel {
    boatName: string;
    strokeAverageSpeedMps: number | null;
}

export class SpeedsPresenter implements Presenter {
    @observable
    public speeds: SpeedViewModel[] = [];

    private subscription: Subscription | undefined;

    constructor(private boatsProgressProvider: BoatsProgressProvider) {}

    public mount(): void {
        this.subscription = debounceThrottleAnimationFrame(this.boatsProgressProvider.get(), 1000)
            .pipe(
                map(boatProgresses =>
                    boatProgresses.map(boatProgress => {
                        return {
                            boatName: boatProgress.name,
                            strokeAverageSpeedMps: boatProgress.strokeAverageSpeedMps,
                        };
                    }),
                ),
            )
            .subscribe(speeds => (this.speeds = speeds));
    }

    public unmount(): void {
        if (this.subscription !== undefined) {
            this.subscription.unsubscribe();
        }
    }
}
