import * as React from 'react';
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react-lite';
import {DragDropContext, DropResult} from 'react-beautiful-dnd';
import {PresenterProps, withPresenter} from '../../../support/with_presenter';
import {BoatsGridDragHandlerPresenter} from './boats_grid_drag_handler_presenter';
import {ApiParticipantToBoatAdder} from './internal/participant_to_boat_adder';
import {Race} from '../../../models/race';
import {ApiDeviceToDeviceAssigner} from './internal/device_to_participant_assigner';

interface OwnProps {
    race: Race;
}

const BoatsGridDragHandlerComponent: FunctionComponent<OwnProps &
    PresenterProps<BoatsGridDragHandlerPresenter>> = observer(({presenter, children}) => {
    return (
        <DragDropContext onDragEnd={(result: DropResult) => presenter.onDragEnd(result)}>{children}</DragDropContext>
    );
});

export const BoatsGridDragHandler = withPresenter<BoatsGridDragHandlerPresenter, OwnProps>(
    (props, component) =>
        new BoatsGridDragHandlerPresenter(
            new ApiParticipantToBoatAdder(
                component.participantsRepositoryFactory.create(props.race),
                component.networkComponent.participantApi,
            ),
            new ApiDeviceToDeviceAssigner(
                props.race.id,
                component.devicesRepositoryFactory.create(props.race),
                component.networkComponent.deviceApi,
            ),
        ),
    BoatsGridDragHandlerComponent,
);
