import * as React from 'react';
import {FunctionComponent, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {BoatsScreenComponent} from '../../../boats_screen_component';
import {ClockPresenter} from './clock_presenter';
import {PresenterProps, withPresenter} from '../../../../../support/with_presenter';
import {DefaultClockProvider} from '../../../internal/clock_provider';
import {Group} from 'react-konva';
import {useImage} from '../../../../hooks/use_image';
import {animated, useChain} from 'react-spring/konva';
import {useSpring} from 'react-spring';

interface OwnProps {
    x: number;
    y: number;
    boatScreenComponent: BoatsScreenComponent;
}

const ClockComponent: FunctionComponent<OwnProps & PresenterProps<ClockPresenter>> = observer(({x, y, presenter}) => {
    const [image] = useImage(require('./rp3-logo.svg'));

    const backgroundRefA = useRef<any>();
    const backgroundPropsA = useSpring({
        from: {width: 0},
        to: {width: 100},
        delay: 5000,
        ref: backgroundRefA,
    });

    const backgroundRefB = useRef<any>();
    const backgroundPropsB = useSpring({
        from: {width: 0},
        to: {width: 100},
        ref: backgroundRefB,
    });

    const foregroundRef = useRef<any>();
    const foregroundProps = useSpring({
        from: {opacity: 0},
        to: {opacity: 1},
        ref: foregroundRef,
    });

    useChain([backgroundRefA, backgroundRefB, foregroundRef]);

    if (presenter.formattedTime === null) {
        return null;
    }

    return (
        <Group x={x} y={y}>
            <animated.Rect {...backgroundPropsA} x={0} y={0} fill="#193F49" height={40} cornerRadius={[5, 0, 0, 5]} />
            <animated.Rect {...backgroundPropsB} x={100} y={0} fill="#ffffff" height={40} cornerRadius={[0, 5, 5, 0]} />
            {image && <animated.Image {...foregroundProps} image={image} x={30} y={11} width={42} height={18} />}
            <animated.Text
                {...foregroundProps}
                x={100}
                y={5}
                text={presenter.formattedTime}
                verticalAlign="middle"
                align="center"
                fontFamily="'Century Gothic', 'Arial', 'Helvetica', sans-serif"
                fontSize={18}
                fill="black"
                width={100}
                height={35}
            />
        </Group>
    );
});

export const Clock = withPresenter<ClockPresenter, OwnProps>(
    (props, component) =>
        new ClockPresenter(
            new DefaultClockProvider(
                props.boatScreenComponent.startTimeProvider,
                component.serverTimeComponent.serverTimeProvider,
            ),
        ),
    ClockComponent,
);
