import * as React from 'react';
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react-lite';
import {PresenterProps, withPresenter} from '../../support/with_presenter';
import {RaceOverviewScreenPresenter} from './race_overview_screen_presenter';
import {RouteComponentProps} from '@reach/router';
import {Race} from '../../models/race';
import {Col} from '../../components/col';
import {Header} from '../components/header';
import {Container} from '../components/container';
import {Row} from '../../components/row';
import {SidebarCol} from '../components/sidebar_col';
import {Heading3} from '../components/heading';
import {ContentCol} from '../components/content_col';
import {Footer} from '../components/footer';
import {PreviousScreenButton} from '../components/buttons';
import {StartButton} from './components/race_control_button';
import {RaceOverview} from './race_overview/race_overview';
import {DeviceStates} from './device_states/device_states';
import {Content} from '../components/content';
import {ConnectionHint} from '../components/connection_hint';
import {ToggleTokenButton} from '../toggle_token/toggle_token_button';
import {StartIntentButton} from './components/start_intent_button';

interface OwnProps {
    race: Race;
}

const RaceOverviewScreenComponent: FunctionComponent<OwnProps &
    RouteComponentProps &
    PresenterProps<RaceOverviewScreenPresenter>> = observer(({presenter}) => {
    const {race} = presenter;

    return (
        <>
            <Header>
                <ConnectionHint race={race} />
                <ToggleTokenButton raceId={race.id} />
            </Header>
            <Content>
                <Container>
                    <Row className="h-100">
                        <SidebarCol numCols={3} className="d-none d-lg-block">
                            <Heading3>Overview</Heading3>
                            <p>Below you can see device state before and during a race.</p>

                            <Row className="overflow-hidden">
                                <Col numCols={12} className="mh-100 overflow-hidden d-flex flex-column">
                                    <DeviceStates race={race} />
                                </Col>
                            </Row>
                        </SidebarCol>
                        <ContentCol className="col-12 col-lg-9">
                            <RaceOverview race={race} />
                        </ContentCol>
                    </Row>
                </Container>
            </Content>
            <Footer>
                <PreviousScreenButton to={`/${race.id}/boats`} state={race}>
                    <span className="ion-ios-arrow-back" />
                    Previous step
                </PreviousScreenButton>
                <span className="flex-grow-1" />
                <StartIntentButton race={race} />
                <StartButton race={race} />
            </Footer>
        </>
    );
});

export const RaceOverviewScreen = withPresenter<RaceOverviewScreenPresenter, OwnProps>(
    (props, component) => new RaceOverviewScreenPresenter(props.race, component.raceProviderFactory.create(props.race)),
    RaceOverviewScreenComponent,
);
