import {Presenter} from '../../../support/with_presenter';
import {observable} from 'mobx';
import {toast} from 'react-toastify';
import {CompositeSubscription} from '../../../support/composite_subscription';
import {debounceThrottleAnimationFrame} from '../../../support/rx_debounce_throttle';
import {Try} from '../../../support/monads/try';
import {BoatWithCombinations} from '../../../business/boats/boats_with_combinations_provider';
import {Observable} from 'rxjs';

export interface BoatsWithCombinationsProvider {
    get(): Observable<Try<BoatWithCombinations[]>>;
}

export class BoatsScreenPresenter implements Presenter {
    @observable
    public boatCombinations: BoatWithCombinations[] = [];

    private subscriptions = new CompositeSubscription();

    constructor(private boatsProvider: BoatsWithCombinationsProvider) {}

    public mount(): void {
        this.subscriptions.add(
            debounceThrottleAnimationFrame(this.boatsProvider.get()).subscribe(boatCombinationsTry => {
                boatCombinationsTry.fold(
                    boatCombinations => {
                        this.boatCombinations = boatCombinations;
                    },
                    () => {
                        toast('Something went wrong while loading boats', {
                            type: toast.TYPE.ERROR,
                        });
                    },
                );
            }),
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }
}
