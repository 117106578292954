import {Presenter} from '../../../../../support/with_presenter';
import {observable} from 'mobx';
import {Observable, Subscription} from 'rxjs';
import {debounceThrottleAnimationFrame} from '../../../../../support/rx_debounce_throttle';

export interface ClockProvider {
    updates: Observable<string | null>;
}

export class ClockPresenter implements Presenter {
    @observable
    public formattedTime: string | null = null;

    private subscription: Subscription | undefined;

    constructor(private clockProvider: ClockProvider) {}

    public mount(): void {
        this.subscription = debounceThrottleAnimationFrame(this.clockProvider.updates).subscribe(
            formattedTime => (this.formattedTime = formattedTime),
        );
    }

    public unmount(): void {
        if (this.subscription !== undefined) {
            this.subscription.unsubscribe();
        }
    }
}
