import {ActiveOverlaysProvider, Overlay} from '../overlays_presenter';
import {combineLatest, Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

/**
 * An overlay strategy is a piece of logic that can provide a stream of updates about
 * a certain overlay, e.g. the ClockOverlayStrategy would be responsible to determine
 * if it is appropriate to show the clock
 */
export interface OverlayStrategy {
    updates: Observable<Overlay[]>;
}

export class StrategyBasedActiveOverlaysProvider implements ActiveOverlaysProvider {
    public overlays = combineLatest(
        this.overlayStrategies.map(overlayStrategy => overlayStrategy.updates.pipe(startWith([]))),
    ).pipe(
        map((results: Overlay[][]) => {
            return results.reduce((overlays, c) => {
                c.forEach(overlay => {
                    if (overlays.indexOf(overlay) === -1) {
                        overlays.push(overlay);
                    }
                });
                return overlays;
            }, []);
        }),
    );

    constructor(private overlayStrategies: OverlayStrategy[]) {}
}
