import {BoatsProgressProvider, SocketBoatsProgressProvider} from '../../business/boats/boats_progress_provider';
import {DefaultBoatsWithCombinationsProvider} from '../../business/boats/boats_with_combinations_provider';
import {DefaultLanesProvider, LanesProvider} from './internal/lanes_provider';
import {lazy} from '../../support/lazy';
import {Race} from '../../models/race';
import {ClientComponent} from '../../client_component';
import {DefaultStartTimeProvider, StartTimeProvider} from '../../business/race/start_time_provider';
import {SplitTimesProvider} from './overlays/internal/overlays/split_times_overlay_strategy';
import {DefaultSplitTimeProvider} from './overlays/internal/overlays/internal/split_time_provider';
import {CollectedSplitTimesProvider} from './overlays/overlays/split_times/split_times_presenter';
import {DefaultCollectedSplitTimesProvider} from './internal/collected_split_times_provider';
import {BoatsProvider} from '../../business/boats/boats_provider';

export interface BoatsScreenComponent {
    boatsProvider: BoatsProvider;
    boatsProgressProvider: BoatsProgressProvider;
    lanesProvider: LanesProvider;
    startTimeProvider: StartTimeProvider;
    splitTimesProvider: SplitTimesProvider;
    collectedSplitTimesProvider: CollectedSplitTimesProvider;
}

export class DefaultBoatsScreenComponent implements BoatsScreenComponent {
    @lazy()
    public get boatsProvider(): BoatsProvider {
        return this.component.boatsProviderFactory.create(this.initialRace);
    }

    @lazy()
    public get boatsProgressProvider(): BoatsProgressProvider {
        return new SocketBoatsProgressProvider(
            this.initialRace.id,
            this.startTimeProvider,
            new DefaultBoatsWithCombinationsProvider(
                this.boatsProvider,
                this.component.participantsProviderFactory.create(this.initialRace),
                this.component.devicesProviderFactory.create(this.initialRace),
            ),
            this.component.networkComponent.boatsProgressSocket,
        );
    }

    @lazy()
    public get lanesProvider(): LanesProvider {
        return new DefaultLanesProvider(this.boatsProgressProvider, 1);
    }

    @lazy()
    public get startTimeProvider(): StartTimeProvider {
        return new DefaultStartTimeProvider(
            this.initialRace,
            this.component.raceProviderFactory.create(this.initialRace),
        );
    }

    @lazy()
    public get splitTimesProvider(): SplitTimesProvider {
        return new DefaultSplitTimeProvider(this.initialRace.id, this.component.networkComponent.splitTimeSocket);
    }

    @lazy()
    public get collectedSplitTimesProvider(): CollectedSplitTimesProvider {
        return new DefaultCollectedSplitTimesProvider(
            this.initialRace.id,
            this.component.networkComponent.splitTimeSocket,
        );
    }

    constructor(private initialRace: Race, private component: ClientComponent) {}
}
