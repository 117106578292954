import {Presenter} from '../../../support/with_presenter';
import {observable} from 'mobx';
import {Race} from '../../../models/race';
import {JoinRaceInteractor} from '../internal/join_race_interactor';

export class CodePresenter implements Presenter {
    @observable
    public busy = false;
    @observable
    public error: string | null = null;
    @observable
    public token: string = '';

    constructor(private onFinish: (race: Race) => void, private joinRaceInteractor: JoinRaceInteractor) {}

    public mount(): void {
        /* Noop */
    }

    public unmount(): void {
        /* Noop */
    }

    public onTokenChange(token: string) {
        this.token = token.toUpperCase();
    }

    public onSubmit() {
        this.onJoinClick();
    }

    public async onJoinClick() {
        this.error = null;
        this.busy = true;
        (await this.joinRaceInteractor.join(this.token)).fold(
            value => {
                this.busy = false;
                this.onFinish(value);
            },
            e => {
                this.busy = false;
                this.error = e.message;
            },
        );
    }
}
