import {Try} from '../../support/monads/try';
import {Device} from '../../models/device';
import {from, Observable, of} from 'rxjs';
import {first, map, shareReplay, switchMap} from 'rxjs/operators';
import {DevicesProvider} from './device_provider';

export interface DevicesForRaceApi {
    getForRaceId(raceId: string): Promise<Try<Device[]>>;
}

export interface DevicesRepository {
    get(): Observable<Device[]>;

    replace(devices: Device[]): void;
}

export class ApiDeviceProvider implements DevicesProvider {
    private observable = from(this.devicesForRaceApi.getForRaceId(this.raceId)).pipe(
        first(),
        switchMap(deviceTry => {
            return deviceTry.fold(
                value => {
                    this.devicesRepository.replace(value);
                    return this.devicesRepository.get().pipe(map(devices => Try.just(devices)));
                },
                (e: Error) => of(Try.raiseError<Device[]>(e)),
            );
        }),
        shareReplay(1),
    );

    constructor(
        private raceId: string,
        private devicesForRaceApi: DevicesForRaceApi,
        private devicesRepository: DevicesRepository,
    ) {}

    public get(): Observable<Try<Device[]>> {
        return this.observable;
    }
}
