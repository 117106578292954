import * as React from 'react';
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react-lite';
import {Race} from '../../../../../models/race';
import {Group, Rect, Text} from 'react-konva';
import {Image as ReactKonvaImage} from 'react-konva/lib/ReactKonvaCore';
import {useImage} from '../../../../hooks/use_image';
import {StatisticRowComponent} from '../components/row';
import {PresenterProps, withPresenter} from '../../../../../support/with_presenter';
import {FinishListPresenter} from './finish_list_presenter';
import {BoatsScreenComponent} from '../../../boats_screen_component';
import {formatFinishTimeMs} from '../../../../../support/format_finish_time';

interface OwnProps {
    race: Race;
    canvasWidth: number;
    canvasHeight: number;
    boatScreenComponent: BoatsScreenComponent;
}

const FinishListComponent: FunctionComponent<OwnProps & PresenterProps<FinishListPresenter>> = observer(
    ({presenter, race, canvasWidth, canvasHeight}) => {
        const {results} = presenter;
        const [image] = useImage(require('./rp3-racing-logo-square.svg'));
        const numColumns = results.length === 1 ? 1 : results.length <= 10 ? 2 : 3;
        const rowsPerColumn = Math.ceil(results.length / numColumns);
        const totalWidth = numColumns * (255 + 36 + 5) + 5;

        return (
            <Group x={(canvasWidth - totalWidth) / 2} y={20}>
                {/* Header */}
                <Rect x={0} y={0} fill="#193F49" width={totalWidth} height={70} cornerRadius={[5, 5, 0, 0]} />
                <Text
                    text={race.distanceMeters + 'm'}
                    verticalAlign="middle"
                    wrap="none"
                    align="left"
                    fontFamily="'Century Gothic', 'Arial', 'Helvetica', sans-serif"
                    fontSize={24}
                    fontStyle="bold"
                    ellipsis={true}
                    lineHeight={36}
                    fill="white"
                    x={10}
                    y={0}
                    width={200}
                    height={50}
                />
                <Text
                    text="RESULTS"
                    verticalAlign="middle"
                    wrap="none"
                    align="left"
                    fontFamily="'Century Gothic', 'Arial', 'Helvetica', sans-serif"
                    fontSize={16}
                    ellipsis={true}
                    lineHeight={16}
                    fill="#00C9FF"
                    x={10}
                    y={28}
                    width={200}
                    height={50}
                />
                {image && <ReactKonvaImage image={image} x={totalWidth - 80} y={15} width={65} height={42} />}

                {/* Background */}
                <Rect
                    x={0}
                    y={70}
                    fill={'rgb(25,63,73,0.5)'}
                    width={totalWidth}
                    height={42 * rowsPerColumn + 5}
                    cornerRadius={[0, 0, 5, 5]}
                />

                {/* Rows */}
                {results.map((result, index) => {
                    const columnNumber = Math.floor(index / rowsPerColumn);
                    const rowNumber = 1 + (index % rowsPerColumn);

                    return (
                        <StatisticRowComponent
                            key={result.boatId}
                            x={columnNumber * (255 + 36 + 5) + 5}
                            y={rowNumber * 36 + rowNumber * 5 + 36}
                            number={result.position === null ? undefined : String(result.position)}
                            highlighted={result.position !== null}
                            highlightFill="#EB5255"
                            name={result.name || '-'}
                            metric={result.timeMillis === null ? undefined : formatFinishTimeMs(result.timeMillis)}
                        />
                    );
                })}
            </Group>
        );
    },
);

export const FinishList = withPresenter<FinishListPresenter, OwnProps>(
    (props, component) => new FinishListPresenter(props.boatScreenComponent.boatsProgressProvider),
    FinishListComponent,
);
