import styled from 'styled-components';
import {baseButtonStyles} from '../../components/button';

interface OwnProps {
    white?: boolean;
}

export const HugeSubmitButton = styled.button`
    ${baseButtonStyles}

    font-size: 1.3em;
    width: 100%;
    height: 72px;
    margin: 10px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    background-color: ${(props: OwnProps) => (props.white === true ? '#ffffff' : '#00c9ff')};

    &:hover,
    &:active,
    &:focus {
        background-color: ${(props: OwnProps) => (props.white === true ? '#ffffff' : '#00c9ff')};
    }

    &:disabled {
        background-color: #54daff;
        cursor: not-allowed;
        &:hover {
            text-decoration: none;
        }
    }

    &:focus {
        box-shadow: 0 0 0 0.2rem
            ${(props: OwnProps) => (props.white === true ? 'rgba(255,255,255,0.5)' : 'rgba(0, 201, 255, 0.5)')};
    }

    span {
        color: ${(props: OwnProps) => (props.white === true ? '#00c9ff' : '#ffffff')};
    }
`;
