import {Presenter} from '../../../support/with_presenter';
import {Observable, Subscription} from 'rxjs';
import {observable} from 'mobx';

export interface StartIntentInteractor {
    intentToStartAtMillis: Observable<number | null>;

    send(): Promise<void>;
    undo(): Promise<void>;
}

export class StartIntentButtonPresenter implements Presenter {
    @observable
    public intentToStartAtMillis: number | null = null;

    private subscription: Subscription | undefined;

    constructor(private startIntentInteractor: StartIntentInteractor) {}

    public mount(): void {
        this.subscription = this.startIntentInteractor.intentToStartAtMillis.subscribe(intentToStartAtMillis => {
            this.intentToStartAtMillis = intentToStartAtMillis;
        });
    }

    public unmount(): void {
        if (this.subscription !== undefined) {
            this.subscription.unsubscribe();
        }
    }

    public onIntentToStartClick() {
        this.startIntentInteractor.send();
    }

    public onUndoIntentToStartClick() {
        this.startIntentInteractor.undo();
    }
}
