import {ServerTime, ServerTimeInteractor} from '../server_time_interactor';
import {Observable} from 'rxjs';
import {scan, map, filter} from 'rxjs/operators';

export class BestServerTimeInteractor implements ServerTimeInteractor {
    private _worst: ServerTime = {
        offsetMillis: Infinity,
        queryDurationMillis: Infinity,
        weight: 0,
    };

    constructor(private serverTimeInteractor: ServerTimeInteractor) {}

    public serverTimes(): Observable<ServerTime> {
        return this.serverTimeInteractor.serverTimes().pipe(
            scan(
                (currentBest, newOffset) => {
                    if (currentBest.value.queryDurationMillis >= newOffset.queryDurationMillis) {
                        return {
                            value: newOffset,
                            isNew: true,
                        } as State;
                    }
                    return {
                        value: currentBest.value,
                        isNew: false,
                    } as State;
                },
                {value: this._worst, isNew: false},
            ),
            filter(state => state.isNew),
            map(state => state.value),
        );
    }
}

interface State {
    value: ServerTime;
    isNew: boolean;
}
