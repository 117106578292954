import {BoatProgress} from '../../../business/boats/boats_progress_provider';
import {Lane} from '../internal/lanes_provider';

export const boatImagesWidth = 64;

export const boatImagesData = {
    front: {
        height: 15,
        width: boatImagesWidth,
        x: 0,
        y: 0,
    },
    rower: {
        height: 28,
        width: boatImagesWidth,
        x: boatImagesWidth,
        y: 0,
    },
    rear: {
        height: 15,
        width: boatImagesWidth,
        x: boatImagesWidth * 2,
        y: 0,
    },
};

export function calculateImageSizeMultiplier(lanes: readonly Lane[]) {
    return lanes.length > 0 ? lanes[0].width / boatImagesWidth : 1;
}

export function getBoatHeight(boat: BoatProgress) {
    return (
        boatImagesData.front.height + boat.participantCount * boatImagesData.rower.height + boatImagesData.rear.height
    );
}

export function getBiggestBoatHeight(boats: readonly BoatProgress[]) {
    return boats.reduce((acc, boat) => {
        const boatHeight = getBoatHeight(boat);
        return acc > boatHeight ? acc : boatHeight;
    }, 0);
}
