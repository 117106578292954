import * as React from 'react';
import {Animate} from 'react-move';
import {FastLayer, Layer} from 'react-konva';
import {easeLinear} from 'd3-ease';

interface OwnProps {
    fast?: boolean;
    viewportOffsetPx: number;
    meterInPx: number;
    children: (data: {viewportOffsetPx: number; meterInPx: number}) => JSX.Element;
}

export const AnimatingViewport: React.FunctionComponent<OwnProps> = ({fast, viewportOffsetPx, meterInPx, children}) => {
    const animate = (
        <Animate
            start={{
                viewportOffsetPx: viewportOffsetPx,
                meterInPx: meterInPx,
            }}
            update={{
                viewportOffsetPx: [viewportOffsetPx],
                meterInPx: [meterInPx],
                timing: {duration: 2500, ease: easeLinear},
            }}>
            {children as any}
        </Animate>
    );

    if (fast) {
        return (
            <FastLayer hitGraphEnabled={false} imageSmoothingEnabled={false}>
                {animate}
            </FastLayer>
        );
    }

    return (
        <Layer hitGraphEnabled={false} imageSmoothingEnabled={false}>
            {animate}
        </Layer>
    );
};
