import {Presenter} from '../../../support/with_presenter';
import {DropResult} from 'react-beautiful-dnd';
import {Participant} from '../../../models/participant';
import {Try} from '../../../support/monads/try';
import {Device} from '../../../models/device';
import {toast} from 'react-toastify';

export interface ParticipantToBoatAdder {
    addToBoat(participantId: string, boatId: string | null): Promise<Try<Participant>>;
}

export interface DeviceToParticipantAssigner {
    assignToParticipant(deviceId: string, participantId: string | null): Promise<Try<Device>>;
}

export enum DraggableType {
    Participant = 'participant',
    Device = 'device',
}

export const DROPPABLE_BOAT_PREFIX = 'boat';
export const DROPPABLE_PARTICIPANT_PREFIX = 'participant';

export const toDroppablePrefix = (prefix: string, identifier: string | number) => {
    return prefix + '|' + String(identifier) + '|';
};

export class BoatsGridDragHandlerPresenter implements Presenter {
    constructor(
        private participantToBoatAdder: ParticipantToBoatAdder,
        private deviceToParticipantAssigner: DeviceToParticipantAssigner,
    ) {}

    public mount(): void {
        /* Noop */
    }

    public unmount(): void {
        /* Noop */
    }

    public async onDragEnd(result: DropResult) {
        switch (result.type as DraggableType) {
            case DraggableType.Participant:
                if (result.destination !== undefined && result.destination !== null) {
                    if (result.destination.droppableId === 'participants-overview') {
                        (await this.participantToBoatAdder.addToBoat(result.draggableId, null)).handleError(e => {
                            toast('Something went wrong while removing participant from boat', {
                                type: toast.TYPE.ERROR,
                            });
                        });
                    } else if (result.destination.droppableId.startsWith(DROPPABLE_BOAT_PREFIX)) {
                        const parts = result.destination.droppableId.split('|');
                        (
                            await this.participantToBoatAdder.addToBoat(result.draggableId, parts[parts.length - 1])
                        ).handleError(e => {
                            toast('Something went wrong while adding participant to boat', {
                                type: toast.TYPE.ERROR,
                            });
                        });
                    }
                }
                break;
            case DraggableType.Device:
                if (result.destination !== undefined && result.destination !== null) {
                    if (result.destination.droppableId === 'devices-overview') {
                        (
                            await this.deviceToParticipantAssigner.assignToParticipant(result.draggableId, null)
                        ).handleError(e => {
                            toast('Something went wrong while removing device from participant', {
                                type: toast.TYPE.ERROR,
                            });
                        });
                    } else if (result.destination.droppableId.startsWith(DROPPABLE_PARTICIPANT_PREFIX)) {
                        const parts = result.destination.droppableId.split('|');
                        (
                            await this.deviceToParticipantAssigner.assignToParticipant(
                                result.draggableId,
                                parts[parts.length - 1],
                            )
                        ).handleError(e => {
                            toast('Something went wrong while assigning device to participant', {
                                type: toast.TYPE.ERROR,
                            });
                        });
                    }
                }
                break;
        }
    }
}
