import {Presenter} from '../../../../support/with_presenter';
import {toast} from 'react-toastify';
import {CompositeSubscription} from '../../../../support/composite_subscription';
import {Observable} from 'rxjs';
import {Try} from '../../../../support/monads/try';
import {Participant} from '../../../../models/participant';
import {observable} from 'mobx';

export interface ParticipantsWithoutBoatProvider {
    get(): Observable<Try<Participant[]>>;
}

export class ParticipantsBlockPresenter implements Presenter {
    @observable
    public participants: Participant[] = [];

    private subscriptions = new CompositeSubscription();

    constructor(private participantsWithoutBoatProvider: ParticipantsWithoutBoatProvider) {}

    public mount(): void {
        this.subscriptions.add(
            this.participantsWithoutBoatProvider.get().subscribe(participantsTry => {
                participantsTry.fold(
                    participants => {
                        this.participants = participants;
                    },
                    () => {
                        toast('Something went wrong while loading participants', {
                            type: toast.TYPE.ERROR,
                        });
                    },
                );
            }),
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }
}
