import {Race} from '../../../../models/race';
import {RaceDistanceUpdater} from '../../select_distance_form_presenter';
import {Try} from '../../../../support/monads/try';

export interface UpdateRaceRepository {
    update(race: Race): void;
}

export interface UpdateRaceApi {
    updateDistanceMeters(raceId: string, distanceMeters: number): Promise<Try<Race>>;
}

export class ApiRaceDistanceUpdater implements RaceDistanceUpdater {
    constructor(
        private raceId: string,
        private raceRepository: UpdateRaceRepository,
        private updateRaceApi: UpdateRaceApi,
    ) {}

    public async update(distanceMeters: number): Promise<Try<Race>> {
        const raceTry = await this.updateRaceApi.updateDistanceMeters(this.raceId, distanceMeters);

        raceTry.map(value => {
            this.raceRepository.update(value);
        });

        return raceTry;
    }
}
