import {Observable} from 'rxjs';
import {Race} from '../../models/race';
import {Try} from '../../support/monads/try';
import {map, tap, shareReplay} from 'rxjs/operators';

export interface RaceProvider {
    get(): Observable<Try<Race>>;
}

export interface RaceRepository {
    get(): Observable<Race>;

    update(races: Race): void;
}

export interface RaceForRaceSocket {
    getForRaceId(raceId: string): Observable<Race>;
}

export class SocketRaceProvider implements RaceProvider {
    protected observable = this.raceForRaceSocket.getForRaceId(this.raceId).pipe(
        tap(race => this.raceRepository.update(race)),
        map(race => Try.just(race)),
        shareReplay(1),
    );

    constructor(
        private raceId: string,
        private raceForRaceSocket: RaceForRaceSocket,
        private raceRepository: RaceRepository,
    ) {}

    public get(): Observable<Try<Race>> {
        return this.observable;
    }
}
