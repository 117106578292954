import {combineLatest, Observable} from 'rxjs';
import {scan, shareReplay, startWith} from 'rxjs/operators';
import {BoatsWithCombinationsProvider, BoatWithCombinations} from './boats_with_combinations_provider';
import {BoatType} from '../../models/boat_type';
import {StartTimeProvider} from '../race/start_time_provider';

export interface BoatsProgressProvider {
    get(): Observable<BoatProgress[]>;
}

export interface BoatProgressStatusUpdate {
    raceId: string;
    boatId: string;

    totalDistanceMeters: number;
    strokeRate: number | null;
    strokeAverageSpeedMps: number | null;
    preliminaryFinishTimeMillis: number | null;
    definitiveFinishTimeMillis: number | null;
}

export interface BoatProgressForRaceSocket {
    getForRaceId(raceId: string): Observable<BoatProgressStatusUpdate>;
}

export interface BoatProgress {
    //Details from boat
    id: string;
    name: string;
    raceId: string;
    type: BoatType | null;

    //Details of race
    startTimeMillis: number | null;

    //Progress
    distanceMeters: number;
    strokeRate: number | null;
    strokeAverageSpeedMps: number | null;
    preliminaryFinishTimeMillis: number | null;
    definitiveFinishTimeMillis: number | null;

    //Added info
    participantCount: number;
}

export class SocketBoatsProgressProvider implements BoatsProgressProvider {
    private observable: Observable<BoatProgress[]> = combineLatest(
        this.startTimeProvider.get().pipe(startWith(null)),
        this.boatsWithCombinationsProvider.get(),
        this.boatProgressForRaceSocket.getForRaceId(this.raceId).pipe(startWith(null)),
    ).pipe(
        scan((boatProgress: BoatProgress[], [startTimeMillis, boatsWithCombinationsTry, boatProgressStatusUpdate]) => {
            return boatsWithCombinationsTry.fold(
                boatsWithCombinations =>
                    this.combine(startTimeMillis, boatProgress, boatsWithCombinations, boatProgressStatusUpdate),
                () => [],
            );
        }, []),
        shareReplay(1),
    );

    constructor(
        private raceId: string,
        private startTimeProvider: StartTimeProvider,
        private boatsWithCombinationsProvider: BoatsWithCombinationsProvider,
        private boatProgressForRaceSocket: BoatProgressForRaceSocket,
    ) {}

    public get(): Observable<BoatProgress[]> {
        return this.observable;
    }

    private combine(
        startTimeMillis: number | null,
        previousBoatProgresses: BoatProgress[],
        boatsWithCombinations: BoatWithCombinations[],
        boatProgressStatusUpdate: BoatProgressStatusUpdate | null,
    ): BoatProgress[] {
        return boatsWithCombinations.map(boatWithCombinations => {
            const previous = previousBoatProgresses.find(p => p.id === boatWithCombinations.boat.id);
            if (previous === undefined || (previous.startTimeMillis !== startTimeMillis && startTimeMillis !== null)) {
                return this.initialUpdate(startTimeMillis, boatWithCombinations);
            }

            if (boatProgressStatusUpdate !== null && boatWithCombinations.boat.id === boatProgressStatusUpdate.boatId) {
                return this.withUpdate(boatWithCombinations, boatProgressStatusUpdate, previous);
            }

            return {
                id: boatWithCombinations.boat.id,
                name: boatWithCombinations.boat.name,
                raceId: boatWithCombinations.boat.raceId,
                type: boatWithCombinations.boat.type,
                startTimeMillis: previous.startTimeMillis,
                preliminaryFinishTimeMillis: previous.preliminaryFinishTimeMillis,
                definitiveFinishTimeMillis: previous.definitiveFinishTimeMillis,
                distanceMeters: previous.distanceMeters,
                strokeRate: previous.strokeRate,
                strokeAverageSpeedMps: previous.strokeAverageSpeedMps,
                participantCount: boatWithCombinations.combinations.length,
            };
        });
    }

    private initialUpdate(startTimeMillis: number | null, boatWithCombinations: BoatWithCombinations): BoatProgress {
        return {
            id: boatWithCombinations.boat.id,
            name: boatWithCombinations.boat.name,
            raceId: boatWithCombinations.boat.raceId,
            type: boatWithCombinations.boat.type,
            startTimeMillis: startTimeMillis,
            participantCount: boatWithCombinations.combinations.length,
            preliminaryFinishTimeMillis: boatWithCombinations.boat.preliminaryFinishTimeMillis,
            definitiveFinishTimeMillis: boatWithCombinations.boat.definitiveFinishTimeMillis,
            distanceMeters: 0,
            strokeRate: null,
            strokeAverageSpeedMps: null,
        };
    }

    private withUpdate(
        boatWithCombinations: BoatWithCombinations,
        boatProgressStatusUpdate: BoatProgressStatusUpdate,
        previous: BoatProgress,
    ): BoatProgress {
        if (this.shouldUpdateFinishTimes(previous, boatProgressStatusUpdate)) {
            return {
                id: boatWithCombinations.boat.id,
                name: boatWithCombinations.boat.name,
                raceId: boatWithCombinations.boat.raceId,
                type: boatWithCombinations.boat.type,
                startTimeMillis: previous.startTimeMillis,
                participantCount: boatWithCombinations.combinations.length,
                preliminaryFinishTimeMillis: this.getPreliminaryFinishTimeMillis(previous, boatProgressStatusUpdate),
                definitiveFinishTimeMillis: this.getDefinitiveFinishTimeMillis(previous, boatProgressStatusUpdate),
                distanceMeters: this.getDistanceMeters(boatProgressStatusUpdate, previous),
                strokeRate: this.getStrokeRate(boatProgressStatusUpdate, previous),
                strokeAverageSpeedMps: this.getStrokeAverageSpeedMps(boatProgressStatusUpdate, previous),
            };
        }
        return {
            id: boatWithCombinations.boat.id,
            name: boatWithCombinations.boat.name,
            raceId: boatWithCombinations.boat.raceId,
            type: boatWithCombinations.boat.type,
            startTimeMillis: previous.startTimeMillis,
            participantCount: boatWithCombinations.combinations.length,
            preliminaryFinishTimeMillis: previous.preliminaryFinishTimeMillis,
            definitiveFinishTimeMillis: previous.definitiveFinishTimeMillis,
            distanceMeters: this.getDistanceMeters(boatProgressStatusUpdate, previous),
            strokeRate: this.getStrokeRate(boatProgressStatusUpdate, previous),
            strokeAverageSpeedMps: this.getStrokeAverageSpeedMps(boatProgressStatusUpdate, previous),
        };
    }

    private getDefinitiveFinishTimeMillis(previous: BoatProgress, boatProgressStatusUpdate: BoatProgressStatusUpdate) {
        return previous.definitiveFinishTimeMillis === null ||
            boatProgressStatusUpdate.definitiveFinishTimeMillis !== null
            ? boatProgressStatusUpdate.definitiveFinishTimeMillis
            : previous.definitiveFinishTimeMillis;
    }

    private getPreliminaryFinishTimeMillis(previous: BoatProgress, boatProgressStatusUpdate: BoatProgressStatusUpdate) {
        return previous.preliminaryFinishTimeMillis === null ||
            boatProgressStatusUpdate.preliminaryFinishTimeMillis !== null
            ? boatProgressStatusUpdate.preliminaryFinishTimeMillis
            : previous.preliminaryFinishTimeMillis;
    }

    private shouldUpdateProgress(boatProgressStatusUpdate: BoatProgressStatusUpdate, previous: BoatProgress) {
        return boatProgressStatusUpdate.totalDistanceMeters > previous.distanceMeters;
    }

    private getStrokeAverageSpeedMps(boatProgressStatusUpdate: BoatProgressStatusUpdate, previous: BoatProgress) {
        return this.shouldUpdateProgress(boatProgressStatusUpdate, previous)
            ? boatProgressStatusUpdate.strokeAverageSpeedMps
            : previous.strokeAverageSpeedMps;
    }

    private getStrokeRate(boatProgressStatusUpdate: BoatProgressStatusUpdate, previous: BoatProgress) {
        return this.shouldUpdateProgress(boatProgressStatusUpdate, previous)
            ? boatProgressStatusUpdate.strokeRate
            : previous.strokeRate;
    }

    private getDistanceMeters(boatProgressStatusUpdate: BoatProgressStatusUpdate, previous: BoatProgress) {
        return this.shouldUpdateProgress(boatProgressStatusUpdate, previous)
            ? boatProgressStatusUpdate.totalDistanceMeters
            : previous.distanceMeters;
    }

    private shouldUpdateFinishTimes(previous: BoatProgress, boatProgressStatusUpdate: BoatProgressStatusUpdate) {
        if (boatProgressStatusUpdate.totalDistanceMeters < previous.distanceMeters) {
            return true;
        }
        return this.statusUpdateHasMoreFinishTimes(previous, boatProgressStatusUpdate);
    }

    private statusUpdateHasMoreFinishTimes(previous: BoatProgress, boatProgressStatusUpdate: BoatProgressStatusUpdate) {
        if (
            boatProgressStatusUpdate.definitiveFinishTimeMillis !== null &&
            previous.definitiveFinishTimeMillis === null
        ) {
            return true;
        }
        return (
            boatProgressStatusUpdate.preliminaryFinishTimeMillis !== null ||
            boatProgressStatusUpdate.definitiveFinishTimeMillis !== null
        );
    }
}
