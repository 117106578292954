import * as React from 'react';
import {FunctionComponent} from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react-lite';
import {PresenterProps, withPresenter} from '../../../../../support/with_presenter';
import {AddParticipantFormPresenter} from './add_participant_form_presenter';
import {ApiCreateParticipantInteractor} from './internal/create_participant_interactor';
import {Race} from '../../../../../models/race';

interface OwnProps {
    race: Race;
}

interface WrapperProps {
    wide?: boolean;
}

const Wrapper = styled.div`
    display: inline-block;
    width: ${(props: WrapperProps) => (props.wide === true ? '100%' : '40px')};
    height: 40px;
    border-radius: 4px;
    border: solid 2px #d8d8d8;
    background-color: #ffffff;
    margin: 10px 10px 0 0;
    position: relative;
    padding: 0 5px;
    font-size: 24px;
    font-weight: bold;
    line-height: 36px;
    text-align: center;
    color: #808080;
    .button {
        width: 27px;
        height: 40px;
        float: left;
        background: none;
        border: none;
        cursor: pointer;
        font-weight: bold;
        margin: 0;
        padding: 0;
        display: inline;
        color: #808080;
        outline: none;
        text-decoration: underline !important;
        &:hover {
            color: #5f5f5f;
        }
    }
    input[type='text'] {
        border: 0;
        float: left;
        width: calc(100% - 60px);
        height: 36px;
        outline: none;
        font-size: 15px;
        font-weight: bold;
    }
`;

const AddParticipantFormComponent: FunctionComponent<OwnProps & PresenterProps<AddParticipantFormPresenter>> = observer(
    ({presenter}) => {
        if (presenter.isAdding) {
            return (
                <Wrapper wide>
                    <form
                        onSubmit={e => {
                            e.preventDefault();
                            presenter.onSubmit();
                        }}>
                        <span className="button button-close ion-md-close" onClick={() => presenter.onCloseClick()} />
                        <input
                            disabled={presenter.busy}
                            type="text"
                            autoFocus
                            value={presenter.participantName}
                            onKeyUp={e => presenter.onParticipantInputKeyPress(e.keyCode)}
                            onChange={e => presenter.onParticipantInputChange(e.target.value)}
                        />
                        <button
                            className="button button-add ion-md-checkmark"
                            disabled={presenter.busy}
                            onClick={() => presenter.onAddClick()}
                        />
                    </form>
                </Wrapper>
            );
        }
        return (
            <Wrapper>
                <button className="button ion-md-add" onClick={() => presenter.onStartAddingClick()} />
            </Wrapper>
        );
    },
);

export const AddParticipantForm = withPresenter<AddParticipantFormPresenter, OwnProps>(
    (props, component) =>
        new AddParticipantFormPresenter(
            new ApiCreateParticipantInteractor(
                props.race.id,
                component.networkComponent.participantApi,
                component.participantsRepositoryFactory.create(props.race),
            ),
        ),
    AddParticipantFormComponent,
);
