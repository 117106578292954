import * as React from 'react';
import {FunctionComponent, useEffect, useState} from 'react';
import {BoatsScreenPresenter} from './boats_screen_presenter';
import {PresenterProps, withPresenter} from '../../support/with_presenter';
import {observer} from 'mobx-react-lite';
import {FastLayer, Rect, Stage} from 'react-konva';
import {calculateViewportOffsetPx, getMeterInPx} from './calculators/viewport_offset_calculator';
import {Lanes} from './components/lanes';
import {AnimatingViewport} from './components/animating_viewport';
import {calculateImageSizeMultiplier} from './support/image_data';
import {round} from './support/round';
import {WaterContent} from './components/water_content';
import {ViewPortSizeProvider} from './support/viewport_sizes';
import {Race} from '../../models/race';
import {DefaultBoatsScreenComponent} from './boats_screen_component';
import {Overlays} from './overlays/overlays';
import {useObservable} from '../hooks/use_observable';
import {calculateMarginToFitBoatsPx} from './support/calculate_margin_to_fit_boats_px';
import {BuoyLanes} from './components/buoy_lanes';
import {LaneNumbers} from './components/lane_numbers';
import {BigBuoys} from './components/big_buoys';

interface OwnProps {
    race: Race;
    viewPortSizeProvider: ViewPortSizeProvider;
}

const BoatsScreenComponent: FunctionComponent<OwnProps & PresenterProps<BoatsScreenPresenter>> = observer(
    ({presenter, viewPortSizeProvider, race, children}) => {
        const {boats, distanceMeters} = presenter;

        const [previousMeterInPx, setPreviousMeterInPx] = useState(Infinity);
        useEffect(() => {
            setPreviousMeterInPx(Infinity);
        }, [viewPortSizeProvider.canvasHeight]);

        const lanes = useObservable(presenter.lanesProvider.lanesStream(viewPortSizeProvider.canvasWidth), [
            viewPortSizeProvider.canvasWidth,
        ]);

        if (lanes === null || boats === null) {
            return null;
        }

        const imageSizeMultiplier = calculateImageSizeMultiplier(lanes);
        const marginTopPx = calculateMarginToFitBoatsPx(boats, race, imageSizeMultiplier);
        const finishLineBlockSizePx = 8 * imageSizeMultiplier;
        const occupiedSpacePx = finishLineBlockSizePx * 2;

        const viewportHeightPx = viewPortSizeProvider.canvasHeight;

        const meterInPx = Math.min(getMeterInPx(viewportHeightPx, boats, marginTopPx), previousMeterInPx);
        if (previousMeterInPx > meterInPx) {
            setPreviousMeterInPx(meterInPx);
        }

        const viewportOffsetPx = calculateViewportOffsetPx(
            boats,
            viewportHeightPx,
            marginTopPx,
            meterInPx,
            occupiedSpacePx,
            distanceMeters,
        );

        return (
            <Stage width={viewPortSizeProvider.canvasWidth} height={viewPortSizeProvider.canvasHeight}>
                <FastLayer hitGraphEnabled={false}>
                    <Rect
                        width={viewPortSizeProvider.canvasWidth}
                        height={viewPortSizeProvider.canvasHeight}
                        fill="#069CC1"
                    />
                    <Lanes
                        lanes={lanes}
                        height={viewPortSizeProvider.canvasHeight}
                        width={viewPortSizeProvider.canvasWidth}
                    />
                </FastLayer>
                <AnimatingViewport fast viewportOffsetPx={viewportOffsetPx} meterInPx={meterInPx}>
                    {(data: {viewportOffsetPx: number; meterInPx: number}) => (
                        <BuoyLanes
                            viewportOffsetPx={round(data.viewportOffsetPx, 2)}
                            meterInPx={round(data.meterInPx, 2)}
                            lanes={lanes}
                            distanceMeters={distanceMeters}
                            canvasHeight={viewPortSizeProvider.canvasHeight}
                            canvasWidth={viewPortSizeProvider.canvasWidth}
                        />
                    )}
                </AnimatingViewport>
                <AnimatingViewport viewportOffsetPx={viewportOffsetPx} meterInPx={meterInPx}>
                    {(data: {viewportOffsetPx: number; meterInPx: number}) => (
                        <>
                            <LaneNumbers
                                lanes={lanes}
                                viewPortOffsetPx={round(data.viewportOffsetPx, 2)}
                                canvasHeight={viewPortSizeProvider.canvasHeight}
                            />
                            <BigBuoys
                                key="big-buoys"
                                meterInPx={round(data.meterInPx, 2)}
                                viewportOffsetPx={round(data.viewportOffsetPx, 2)}
                                distanceMeters={distanceMeters}
                                canvasWidth={viewPortSizeProvider.canvasWidth}
                                canvasHeight={viewPortSizeProvider.canvasHeight}
                            />
                            <WaterContent
                                viewportOffsetPx={round(data.viewportOffsetPx, 2)}
                                meterInPx={round(data.meterInPx, 2)}
                                distanceMeters={distanceMeters}
                                lanes={lanes}
                                boats={boats}
                                finishLineBlockSizePx={finishLineBlockSizePx}
                                imageSizeMultiplier={imageSizeMultiplier}
                                marginTopPx={marginTopPx}
                                canvasWidth={viewPortSizeProvider.canvasWidth}
                                canvasHeight={viewPortSizeProvider.canvasHeight}
                                boatsScreenComponent={presenter.boatsScreenComponent}
                            />
                        </>
                    )}
                </AnimatingViewport>
                <Overlays
                    race={race}
                    lanes={lanes}
                    boatScreenComponent={presenter.boatsScreenComponent}
                    canvasWidth={viewPortSizeProvider.canvasWidth}
                    canvasHeight={viewPortSizeProvider.canvasHeight}
                />
                {children}
            </Stage>
        );
    },
);

export const BoatsScreen = withPresenter<BoatsScreenPresenter, OwnProps>((props, component) => {
    const boatsScreenComponent = new DefaultBoatsScreenComponent(props.race, component);
    return new BoatsScreenPresenter(
        props.race.distanceMeters,
        props.viewPortSizeProvider,
        boatsScreenComponent.boatsProgressProvider,
        boatsScreenComponent.lanesProvider,
        boatsScreenComponent,
    );
}, BoatsScreenComponent);
