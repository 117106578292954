import {Observable} from 'rxjs';
import {Participant} from '../../../../../models/participant';
import {Try} from '../../../../../support/monads/try';
import {ParticipantsProvider} from '../../../../../business/participants/participant_provider';
import {map} from 'rxjs/operators';
import {ParticipantsForBoatProvider} from './combination_provider';

export class DefaultParticipantsForBoatProvider implements ParticipantsForBoatProvider {
    constructor(private boatId: string, private participantsProvider: ParticipantsProvider) {}

    public get(): Observable<Try<Participant[]>> {
        return this.participantsProvider
            .get()
            .pipe(
                map(participantsTry =>
                    participantsTry.map(participants =>
                        participants.filter(participant => participant.boatId === this.boatId),
                    ),
                ),
            );
    }
}
