import {Presenter} from '../../support/with_presenter';
import {Race} from '../../models/race';
import {observable} from 'mobx';
import {toast} from 'react-toastify';
import {RaceApi} from '../../network/race_api';
import {isRace} from '../../support/is_race';

export class RaceLoadingScreenPresenter implements Presenter {
    @observable
    public race: Race | null;

    constructor(potentialInitialRace: Race | unknown, private raceId: string, private raceApi: RaceApi) {
        this.race = isRace(potentialInitialRace) ? potentialInitialRace : null;
    }

    public async mount() {
        if (this.race === null) {
            (await this.raceApi.get(this.raceId)).fold(
                race => (this.race = race),
                () => {
                    toast('Something went wrong while loading race', {
                        type: toast.TYPE.ERROR,
                    });
                },
            );
        }
    }

    public unmount(): void {}
}
