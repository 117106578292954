import {Presenter} from '../../support/with_presenter';
import {Race} from '../../models/race';
import {observable} from 'mobx';
import {ToggleTokenSocket} from '../../network/toggle_token_socket';
import {CompositeSubscription} from '../../support/composite_subscription';
import {RaceProvider} from '../../business/race/race_provider';

export class BigScreenPresenter implements Presenter {
    @observable
    public race: Race;

    @observable
    public isTokenVisible = false;

    private subscriptions = new CompositeSubscription();

    constructor(initialRace: Race, private raceProvider: RaceProvider, private toggleTokenSocket: ToggleTokenSocket) {
        this.race = initialRace;
    }

    public async mount() {
        this.subscriptions.add(
            this.raceProvider.get().subscribe(raceTry => {
                raceTry.map(race => (this.race = race));
            }),
        );

        this.subscriptions.add(
            this.toggleTokenSocket
                .getForRaceId(this.race.id)
                .subscribe(data => (this.isTokenVisible = data.isTokenVisible)),
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }
}
