import * as React from 'react';
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react-lite';
import {PresenterProps, withPresenter} from '../../../support/with_presenter';
import {CreatePresenter} from './create_presenter';
import {Race} from '../../../models/race';
import {CodeContainer} from '../components/code_container';
import {Row} from '../../../components/row';
import {Col} from '../../../components/col';
import {FancyInput} from '../components/fancy_input';
import {HugeSubmitButton} from '../components/huge_submit_button';
import {DefaultCreateRaceInteractor} from '../internal/create_race_interactor';
import {HugeLink} from '../components/huge_link';

interface OwnProps {
    onFinish: (race: Race) => void;
}

const CreateComponent: FunctionComponent<OwnProps & PresenterProps<CreatePresenter>> = observer(({presenter}) => {
    return (
        <CodeContainer className={presenter.error !== null ? 'shake' : undefined}>
            <Row>
                <Col numCols={12}>
                    <form
                        onSubmit={e => {
                            e.preventDefault();
                            presenter.onSubmit();
                        }}>
                        <FancyInput
                            label="Your email address"
                            autoFocus
                            type="email"
                            value={presenter.email}
                            onChange={e => presenter.onEmailChange(e.target.value)}
                        />
                    </form>
                </Col>
            </Row>
            <Row>
                <Col numCols={12}>
                    <HugeSubmitButton disabled={!presenter.canSubmit} onClick={() => presenter.onCreateClick()}>
                        <span>Create race</span>
                    </HugeSubmitButton>
                </Col>
            </Row>
            <Row>
                <Col numCols={12}>
                    <HugeLink white to="/">
                        <span>Back to joining</span>
                    </HugeLink>
                </Col>
            </Row>
        </CodeContainer>
    );
});

export const Create = withPresenter<CreatePresenter, OwnProps>(
    (props, component) =>
        new CreatePresenter(props.onFinish, new DefaultCreateRaceInteractor(component.networkComponent.raceApi)),
    CreateComponent,
);
