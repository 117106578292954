import * as React from 'react';
import {FunctionComponent, useState} from 'react';
import styled from 'styled-components';
import {Block, BlockCircle} from '../../../components/block';

interface OwnProps {
    disabled: boolean;
    initialDistance?: number;
    distanceMeters: number;
    onChange: (distanceMeters: number) => void;
}

const DistanceInput = styled.input`
    text-align: center;
    width: calc(100% - 20px);
    height: 100%;
    margin: 10px;
    padding: 5px;
    overflow: hidden;
    background: transparent;
    border: none;
    border-bottom: 5px solid #f0f0f0;

    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: inherit;

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    [type='number'] {
        -moz-appearance: textfield; /* Firefox */
    }
`;

export const DistanceBlockForm: FunctionComponent<OwnProps> = ({
    distanceMeters,
    initialDistance,
    disabled,
    onChange,
}) => {
    const [state, setState] = useState<number | string>(initialDistance || 100);

    return (
        <Block disabled={disabled} active={distanceMeters === state}>
            <BlockCircle disabled={disabled} active={distanceMeters === state}>
                <form
                    onSubmit={e => {
                        e.preventDefault();
                        onChange(typeof state !== 'string' ? state : 100);
                    }}>
                    <DistanceInput
                        type="number"
                        disabled={disabled}
                        value={state}
                        min={1}
                        onChange={e => setState(parseInt(e.target.value, 10) || '')}
                    />
                </form>
            </BlockCircle>
        </Block>
    );
};
