import {Presenter} from '../../../../../support/with_presenter';
import {BoatsProgressProvider} from '../../../../../business/boats/boats_progress_provider';
import {observable} from 'mobx';
import {Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {debounceThrottleAnimationFrame} from '../../../../../support/rx_debounce_throttle';

export interface ResultItem {
    boatId: string;
    name: string;
    position: number | null;
    timeMillis: number | null;
}

export class FinishListPresenter implements Presenter {
    @observable
    public results: ResultItem[] = [];

    private subscription: Subscription | undefined;

    constructor(private boatsProgressProvider: BoatsProgressProvider) {}

    public mount(): void {
        this.subscription = debounceThrottleAnimationFrame(this.boatsProgressProvider.get(), 250)
            .pipe(
                map(boatProgresses => {
                    const allPositionsHaveTimes = boatProgresses.every(
                        boatProgress => boatProgress.definitiveFinishTimeMillis !== null,
                    );

                    if (allPositionsHaveTimes) {
                        return [...boatProgresses]
                            .sort((a, b) => {
                                if (a.definitiveFinishTimeMillis === null) {
                                    return 1;
                                }
                                if (b.definitiveFinishTimeMillis === null) {
                                    return -1;
                                }
                                if (a.definitiveFinishTimeMillis === b.definitiveFinishTimeMillis) {
                                    return 0;
                                }
                                return a.definitiveFinishTimeMillis < b.definitiveFinishTimeMillis ? -1 : 1;
                            })
                            .map((boatProgress, index) => {
                                return {
                                    boatId: boatProgress.id,
                                    position: index + 1,
                                    timeMillis: boatProgress.definitiveFinishTimeMillis,
                                    name: boatProgress.name,
                                };
                            });
                    }

                    return boatProgresses
                        .filter(boatProgress => boatProgress.definitiveFinishTimeMillis !== null)
                        .map(boatProgress => {
                            return {
                                boatId: boatProgress.id,
                                position: null,
                                timeMillis: boatProgress.definitiveFinishTimeMillis,
                                name: boatProgress.name,
                            };
                        });
                }),
            )
            .subscribe(results => {
                this.results = results;
            });
    }

    public unmount(): void {
        if (this.subscription !== undefined) {
            this.subscription.unsubscribe();
        }
    }
}
