import {OverlayStrategy} from '../strategy_based_active_overlays_provider';
import {Observable} from 'rxjs';
import {Overlay} from '../../overlays_presenter';
import {lazy} from '../../../../../support/lazy';
import {BoatsProgressProvider} from '../../../../../business/boats/boats_progress_provider';
import {map} from 'rxjs/operators';
import {debounceThrottle} from '../../../../../support/rx_debounce_throttle';

export class FinishListOverlayStrategy implements OverlayStrategy {
    @lazy()
    public get updates(): Observable<Overlay[]> {
        return debounceThrottle(this.boatsProgressProvider.get(), 1000).pipe(
            map(boatProgresses => {
                return boatProgresses.some(boatProgress => boatProgress.definitiveFinishTimeMillis !== null)
                    ? [Overlay.FinishList]
                    : [];
            }),
        );
    }

    constructor(private boatsProgressProvider: BoatsProgressProvider) {}
}
