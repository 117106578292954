import * as React from 'react';
import {FunctionComponent, memo} from 'react';
import {Lane} from '../internal/lanes_provider';
import {Buoys} from './buoys';

interface OwnProps {
    lanes: readonly Lane[];
    canvasHeight: number;
    canvasWidth: number;
    meterInPx: number;
    viewportOffsetPx: number;
    distanceMeters: number;
}

export const BuoyLanes: FunctionComponent<OwnProps> = memo(
    ({lanes, canvasHeight, meterInPx, viewportOffsetPx, canvasWidth, distanceMeters}) => {
        if (lanes.length === 0) {
            return null;
        }

        const referenceLane = lanes[0];
        const lastLane = lanes[lanes.length - 1];

        return (
            <>
                {[...lanes].map(lane => (
                    <Buoys
                        key={'lane-' + lane.x + '-buoy'}
                        x={lane.x}
                        meterInPx={meterInPx}
                        viewportOffsetPx={viewportOffsetPx}
                        canvasHeight={canvasHeight}
                    />
                ))}
                <Buoys
                    x={lastLane.x + referenceLane.width}
                    meterInPx={meterInPx}
                    viewportOffsetPx={viewportOffsetPx}
                    canvasHeight={canvasHeight}
                />
            </>
        );
    },
);
