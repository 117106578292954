import {Observable} from 'rxjs';
import {shareReplay, startWith, map, distinctUntilChanged} from 'rxjs/operators';
import {Try} from '../../support/monads/try';
import {Race} from '../../models/race';
import {RaceProvider} from './race_provider';
import {RaceState} from '../../enumerations/race_state';

export interface StartTimeProvider {
    get(): Observable<number | null>;
}

export class DefaultStartTimeProvider implements StartTimeProvider {
    private observable: Observable<number | null> = this.raceProvider.get().pipe(
        startWith(Try.just(this.initialRace)),
        map(raceTry => {
            return raceTry.fold(
                race => {
                    if (race.state === RaceState.IDLE || race.state === RaceState.ABORTED) {
                        return null;
                    }
                    return race.startTimeMillis;
                },
                () => null,
            );
        }),
        distinctUntilChanged(),
        shareReplay(1),
    );

    constructor(private initialRace: Race, private raceProvider: RaceProvider) {}

    public get(): Observable<number | null> {
        return this.observable;
    }
}
