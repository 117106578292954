import * as React from 'react';
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react-lite';
import {PresenterProps, withPresenter} from '../../../../support/with_presenter';
import {DevicesBlockPresenter} from './devices_block_presenter';
import {Block} from '../components/block';
import {Row} from '../../../../components/row';
import {Col} from '../../../../components/col';
import {Droppable} from 'react-beautiful-dnd';
import {DraggableType} from '../boats_grid_drag_handler_presenter';
import {Race} from '../../../../models/race';
import {DraggableDeviceBlockItem} from '../components/draggable_device_block';
import {DefaultDevicesWithoutParticipantProvider} from './internal/devices_without_participant_provider';
import {BlockItem} from '../components/block_item';
import {Can} from '../../../components/can';
import {Permission} from '../../../../enumerations/permission';

interface OwnProps {
    race: Race;
}

const DevicesBlockComponent: FunctionComponent<OwnProps & PresenterProps<DevicesBlockPresenter>> = observer(
    ({presenter, race}) => {
        const devices = presenter.devices;

        return (
            <Block title="Devices" icon="ion-md-people">
                <Row>
                    <Col numCols={12}>
                        <Droppable droppableId="devices-overview" type={DraggableType.Device}>
                            {provided => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    <Can permission={Permission.EDIT_DEVICES} race={race}>
                                        <BlockItem inactive>Reset device</BlockItem>
                                    </Can>
                                    {devices.map((device, index) => (
                                        <DraggableDeviceBlockItem
                                            dark
                                            key={device.id}
                                            index={index}
                                            id={device.id}
                                            type={DraggableType.Device}
                                            device={device}
                                            race={race}
                                        />
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </Col>
                </Row>
            </Block>
        );
    },
);

export const DevicesBlock = withPresenter<DevicesBlockPresenter, OwnProps>(
    (props, component) =>
        new DevicesBlockPresenter(
            new DefaultDevicesWithoutParticipantProvider(component.devicesProviderFactory.create(props.race)),
        ),
    DevicesBlockComponent,
);
