import {Observable} from 'rxjs';
import {Try} from '../../../../../support/monads/try';
import {Participant} from '../../../../../models/participant';
import {ParticipantsWithoutBoatProvider} from '../participants_block_presenter';
import {map} from 'rxjs/operators';
import {ParticipantsProvider} from '../../../../../business/participants/participant_provider';

export class DefaultParticipantsWithoutBoatProvider implements ParticipantsWithoutBoatProvider {
    constructor(private participantsProvider: ParticipantsProvider) {}

    public get(): Observable<Try<Participant[]>> {
        return this.participantsProvider
            .get()
            .pipe(
                map(participantsTry =>
                    participantsTry.map(participants =>
                        participants.filter(participant => participant.boatId === null),
                    ),
                ),
            );
    }
}
